/* eslint-disable no-param-reassign */
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { TableCell, TableRow } from "vapi-ui-common";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import StatusIndicator from "../../../../components/StatusIndicator/StatusIndicator";
import ContextMenuCell from "../../../../components/tableCells/tableCells/ContextMenuCell/ContextMenuCell";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import useStores from "../../../../stores/useStores";
import useRowHandlers from "../../hooks/useRowHandlers";
import styles from "./commonLanguageTable.module.scss";

interface CLLeftRowProps {
  item: AccessoryCommonLanguageItem;
  readonly?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const CLLeftRow = ({ item, readonly, children }: CLLeftRowProps) => {
  const { handleRichTextChange, copyItem, deleteItem } = useRowHandlers(item);
  const { teamStore } = useStores();

  const showSyncAmsAds =
    teamStore.team.allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true" &&
    !readonly &&
    item.hasADSUpdates;

  return (
    <TableRow>
      {!readonly && (
        <TableCell className={cx(styles.cell, styles.actionButtons)}>
          <ContextMenuCell
            disabled={readonly}
            description={item.title}
            itemType="Common Language"
            deleteItem={deleteItem}
            copyItem={copyItem}
            deleteItemText={item.publishedDate ? "Archive" : "Delete"}
          />
        </TableCell>
      )}

      {/* Accessory Name */}
      <TableCell
        className={cx(styles.cell, styles.textArea, {
          [styles.syncAmsAdsCell]: showSyncAmsAds,
        })}
      >
        {item.isArchived && <StatusIndicator>Archived</StatusIndicator>}
        <AccessoryRichText
          value={item.title}
          onBlur={handleRichTextChange("title")}
          disabled={readonly}
          required={!item.title}
        />
        {showSyncAmsAds && { children }}
      </TableCell>

      {/* Copy */}
      <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
        <AccessoryRichText
          value={item.copy}
          onBlur={handleRichTextChange("copy")}
          disabled={readonly}
          required={!item.copy}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(CLLeftRow);
