import { action, observable } from "mobx";
import UserGroups from "../models/common/UserGroups";
import {
  LanguagePermissions,
  UserAccPermissions,
  UserInfo,
  UserModules,
} from "../models/user/user.model";
import { VehicleTeam } from "../models/vehicleData/vehicleData.model";

class UserStore {
  @observable brand = "";

  @observable groups = "" as UserGroups;

  @observable langPermissions = {} as LanguagePermissions;

  @observable modules = {
    Accessories: {},
    CommonLanguage: {},
    ProductTeam: {},
    AgencyTeam: {},
    Disclaimers: {},
    SeriesManager: {},
    SeriesSelections: {},
  } as UserModules;

  @observable series: string[] = [];

  @observable objectId = "";

  @observable teamModule = {} as UserAccPermissions;

  @observable team = "";

  setTeamModule = () => {
    this.teamModule = this.modules.Accessories;
  };

  @action setUserInfo = (userInfo: UserInfo) => {
    Object.assign(this, userInfo);
    this.setTeamModule(); // team module is not currently being used; may drop this line
  };

  setTeam = (team: string) => {
    this.team = team;
  };

  getTeam = (): VehicleTeam => {
    if (!this.team) {
      if (this.isNationalUser()) return VehicleTeam.NATIONAL_ACC_TEAM;
      if (this.isGstUser()) return VehicleTeam.GST_ACC_TEAM;
      if (this.isSpanishUser()) return VehicleTeam.SPANISH;
    }

    return this.team as VehicleTeam;
  };

  isNationalUser = () => {
    return this.groups.indexOf(UserGroups.VAPI_ACC_NTNL_USER) !== -1;
  };

  isGstUser = () => {
    return this.groups.includes("VAPI_acc_gst_user");
  };

  isToyotaNationalUser = () => {
    return this.isNationalUser() && this.brand === "toyota";
  };

  isLexusUser = () => {
    return this.brand === "lexus";
  };

  isSpanishUser = () => {
    return this.langPermissions.ES?.canEdit;
  };
}

export default UserStore;
