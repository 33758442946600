import adminApolloClient from "../adminApolloClient";
import {
  CreateSeriesSelectionsDocument,
  CreateSeriesSelectionsMutation,
  CreateSeriesSelectionsMutationVariables,
  GetFuelTypesDocument,
  GetFuelTypesQuery,
  GetFuelTypesQueryVariables,
  Language,
  ReadSeriesDocument,
  ReadSeriesQuery,
  ReadSeriesQueryVariables,
  ReadSeriesSelectionsDocument,
  ReadSeriesSelectionsQuery,
  ReadSeriesSelectionsQueryVariables,
  ReadVehicleModelsDocument,
  ReadVehicleModelsQuery,
  ReadVehicleModelsQueryVariables,
  Status,
  Team,
} from "../gql/adminGenerated";
import { FuelTypeResponse } from "../models/accessories/fuelType.model";
import {
  DashboardDetail,
  Series,
  UserSeries,
  VehicleTeam,
} from "../models/vehicleData/vehicleData.model";
import VehicleModelsResponse from "../models/vehicleModels/VehicleModelsResponse";
import API from "./api";

const RESOURCE_PATH = "/admin";

// Data
//
const DATA_PATH = "/data";

export const getDashboardDetails = (brand: string, team: VehicleTeam) => {
  return API.get<DashboardDetail>(
    // `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/dashboard`
    `vehicle-admin/${DATA_PATH}/${brand}/${team}/dashboard`
  );
};

export const getPermissions = (data: { token: string; objectId: string }) => {
  return API.post(`${RESOURCE_PATH}/permissions`, data);
};

// Series
export type SeriesResponse = {
  series: Series;
};

export const getSeries = async (
  variables: ReadSeriesQueryVariables
): Promise<SeriesResponse> => {
  const { data } = await adminApolloClient.query<
    ReadSeriesQuery,
    ReadSeriesQueryVariables
  >({
    fetchPolicy: "network-only",
    query: ReadSeriesDocument,
    variables,
  });

  return data.readSeries.body;
};

// Series Selections
export type UserSeriesResponse = {
  seriesSelections: UserSeries[];
};

export const getUserSeries = async (
  variables: ReadSeriesSelectionsQueryVariables
): Promise<UserSeriesResponse> => {
  const { data } = await adminApolloClient.query<
    ReadSeriesSelectionsQuery,
    ReadSeriesSelectionsQueryVariables
  >({
    fetchPolicy: "network-only",
    query: ReadSeriesSelectionsDocument,
    variables,
  });

  return data.readSeriesSelections.body;
};

export const updateUserSeries = async (
  variables: CreateSeriesSelectionsMutationVariables
): Promise<UserSeriesResponse> => {
  const { data } = await adminApolloClient.mutate<
    CreateSeriesSelectionsMutation,
    CreateSeriesSelectionsMutationVariables
  >({
    mutation: CreateSeriesSelectionsDocument,
    variables,
  });

  if (!data) {
    throw new Error("Failed to update User Series");
  }

  return data.createSeriesSelections.body;
};

export const getAvailableModels = async (
  variables: Omit<
    ReadVehicleModelsQueryVariables,
    "team" | "language" | "filter" | "includeAll"
  >
): Promise<VehicleModelsResponse[]> => {
  const { data } = await adminApolloClient.query<
    ReadVehicleModelsQuery,
    ReadVehicleModelsQueryVariables
  >({
    query: ReadVehicleModelsDocument,
    variables: {
      ...variables,
      team: Team.ProductTeam,
      language: Language.En,
      filter: { status: Status.Published },
      includeAll: false,
    },
    fetchPolicy: "network-only",
  });

  return data.readVehicleModels.body;
};

// Fuel Types
export const getFuelTypes = async (
  variables: GetFuelTypesQueryVariables
): Promise<FuelTypeResponse> => {
  const { data } = await adminApolloClient.query<
    GetFuelTypesQuery,
    GetFuelTypesQueryVariables
  >({
    query: GetFuelTypesDocument,
    variables,
    fetchPolicy: "network-only",
  });

  return data.getFuelTypes.body;
};
