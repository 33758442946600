import { FetchResult } from "@apollo/client";
import { createContext } from "react";
import {
  DeleteAccessoryMutation,
  RefItem,
  UpdateGoLiveDateMutation,
} from "../../../../gql/generated";
import AccessoryItemVM, {
  GoLiveDateItem,
} from "../../../../models/accessories/AccessoryItemVM";
import {
  AccessoriesLangMap,
  GoLiveDateItemgMap,
  ProductTypeLangMap,
} from "../../../../models/accessories/accessories.models";
import {
  Language,
  LanguagePermissions,
} from "../../../../models/user/user.model";
import { GradeItem, ModelItem } from "../../../../stores/modelStore";

type TUserDetails = {
  brand: string;
  team: string;
  region: string;
  lang: string;
};

type TDraftDetails = {
  seriesId: string;
  modelYear: number;
  version: string;
};

interface IAccessoriesEntryScreenContextProps {
  userDetails: TUserDetails;
  draftDetails: TDraftDetails;
  languagePermissions?: LanguagePermissions;
  teamLang: Language;
  readOnly: boolean;
  sortMode: boolean;
  allData: AccessoryItemVM[];
  accessoriesLangMap: AccessoriesLangMap;
  productTypeLangMap: ProductTypeLangMap;
  selectedLanguages: Language[];
  deleteItem: (item: AccessoryItemVM) => void;
  deleteAccessoryItem: (
    item: AccessoryItemVM
  ) => Promise<
    FetchResult<
      DeleteAccessoryMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  addCopiedItem: (item: AccessoryItemVM) => void;
  filterItems: (
    searchTextParam: string,
    productTypeFiltersParam: string[],
    inProgressFilterParam: boolean,
    syncChangesFilter?: boolean
  ) => void;
  saveAccessoryItem: (
    item: AccessoryItemVM,
    language?: Language
  ) => Promise<void>;
  setSort: (prop: string) => void;
  addProductType: (item: RefItem) => void;
  isLexusUser: boolean;
  isNationalUser: boolean;
  isSpanish: boolean;
  gradesData: GradeItem[];
  updateGoLiveDates: (
    goLiveDates: GoLiveDateItem[]
  ) => Promise<
    FetchResult<
      UpdateGoLiveDateMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  goLiveDateLangEnMapData: GoLiveDateItemgMap;
  modelItemList: ModelItem[];
}

const defaultUserDetails = {
  brand: "",
  team: "",
  region: "",
  lang: "",
};

const defaultDraftDetails = {
  seriesId: "",
  modelYear: 0,
  version: "",
};

const defaultAccessoriesEntryScreenContext: IAccessoriesEntryScreenContextProps =
  {
    userDetails: defaultUserDetails,
    draftDetails: defaultDraftDetails,
    teamLang: Language.EN,
    readOnly: false,
    sortMode: false,
    allData: [],
    accessoriesLangMap: {},
    productTypeLangMap: {},
    selectedLanguages: [],
    deleteItem: (item: AccessoryItemVM) => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
    deleteAccessoryItem: async (item: AccessoryItemVM) => ({}), // eslint-disable-line @typescript-eslint/no-unused-vars
    addCopiedItem: (item: AccessoryItemVM) => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
    filterItems: (
      searchTextParam: string, // eslint-disable-line @typescript-eslint/no-unused-vars
      productTypeFiltersParam: string[], // eslint-disable-line @typescript-eslint/no-unused-vars
      inProgressFilterParam: boolean, // eslint-disable-line @typescript-eslint/no-unused-vars
      syncChangesFilter?: boolean // eslint-disable-line @typescript-eslint/no-unused-vars
    ) => undefined,
    saveAccessoryItem: async (
      item: AccessoryItemVM, // eslint-disable-line @typescript-eslint/no-unused-vars
      language?: Language // eslint-disable-line @typescript-eslint/no-unused-vars
    ) => undefined,
    setSort: (prop: string) => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
    addProductType: (item: RefItem) => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
    isLexusUser: false,
    isNationalUser: false,
    isSpanish: false,
    gradesData: [],
    updateGoLiveDates: async (goLiveDates: GoLiveDateItem[]) => ({}), // eslint-disable-line @typescript-eslint/no-unused-vars
    goLiveDateLangEnMapData: {},
    modelItemList: [],
  };

export default createContext<IAccessoriesEntryScreenContextProps>(
  defaultAccessoriesEntryScreenContext
);

interface IAccessoryContextProps {
  index: number;
  accessoryItem: AccessoryItemVM;
  onAmsClick?: () => void;
}

const defaultAccessoryContext: IAccessoryContextProps = {
  index: 0,
  accessoryItem: {} as AccessoryItemVM,
  onAmsClick: () => undefined,
};

export const AccessoryContext = createContext<IAccessoryContextProps>(
  defaultAccessoryContext
);
