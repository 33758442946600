import { action, computed, observable } from "mobx";
import { BRAND_TOYOTA } from "../constants/Brand";
import IDValueType from "../models/common/IDValueType.model";
import VehicleModelsResponse from "../models/vehicleModels/VehicleModelsResponse";
import { getAvailableModels, getFuelTypes } from "../webservices/adminApi";
import { Brand } from "../gql/adminGenerated";

export interface ModelItem {
  code: string;
  modelId: string;
  grade: string;
  description: string;
  goLiveDate: string;
}
export interface GradeItem {
  value: string;
  modelItems: ModelItem[];
  id: string;
}

class VehicleModelsStore {
  @observable data: VehicleModelsResponse[] = [];

  @observable transformGradeData: GradeItem[] = [];

  @observable transformedModelItems: ModelItem[] = [];

  @observable fuelTypes: IDValueType[] = [];

  @action fetchModels = async (
    series?: string,
    year?: string,
    brand?: string
  ) => {
    const response = await getAvailableModels({
      seriesId: series ?? "",
      modelYear: parseInt(year ?? "0", 10),
      brand: brand as Brand,
    });
    this.data = structuredClone(response);
    const grades: { [grade: string]: GradeItem } = {};
    const modelItems: ModelItem[] = [];
    if (this.data) {
      this.data.forEach((element: VehicleModelsResponse) => {
        const gradeId = element.grade;
        let gradeValue = "";
        if (brand === BRAND_TOYOTA) {
          gradeValue = element.gradeValue ? element.gradeValue : gradeId;
        }
        if (!grades[gradeValue]) {
          grades[gradeValue] = {
            value: gradeValue,
            modelItems: [],
            id: gradeId,
          };
        }
        if (grades[gradeValue]) {
          const modelItem: ModelItem = {
            grade: gradeValue,
            code: element.code,
            modelId: element.id,
            description: element.description,
            goLiveDate: element.goLiveDate,
          };
          grades[gradeValue].modelItems.push(modelItem);
          modelItems.push(modelItem);
          grades[gradeValue].modelItems.sort((a, b) => {
            return +a.code - +b.code;
          });
        }
      });
    }
    this.transformGradeData = Object.values(grades);
    modelItems.sort((a, b) => {
      return +a.code - +b.code;
    });

    this.transformedModelItems = modelItems;

    return this.data;
  };

  @action fetchFuelTypes = async (brand?: string) => {
    const response = await getFuelTypes({ brand: brand as Brand });
    const fuelTypes = Object.values(response.fuelTypes).map(
      (item) => new IDValueType(item.id, item.type)
    );
    this.fuelTypes = fuelTypes;

    return this.fuelTypes;
  };

  @action filterFuelTypes = () => {
    if (this.data.length && this.fuelTypes.length) {
      const modelDir: Record<string, any> = this.data.reduce((acc, model) => {
        if (!acc[model.fuelType]) {
          acc[model.fuelType] = model;
        }
        return acc;
      }, {} as Record<string, any>);
      this.fuelTypes = this.fuelTypes.filter(
        (fuelType) => modelDir[fuelType.id]
      );
    }
  };

  @action init = async (series?: string, year?: string, brand?: string) => {
    await Promise.all([
      this.fetchModels(series, year, brand),
      this.fetchFuelTypes(brand),
    ]);
    this.filterFuelTypes();
  };

  @computed get gradesData() {
    return this.transformGradeData;
  }

  @computed get modelItems() {
    return this.transformedModelItems;
  }

  @action reset = () => {
    this.data = [];
    this.transformedModelItems = [];
    this.transformGradeData = [];
  };
}

export default VehicleModelsStore;
