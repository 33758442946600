import { toast } from "react-toastify";
import useStores from "../../../stores/useStores";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";

const useCreateCommonLanguage = () => {
  const { commonLanguageStore } = useStores();

  const createCommonLanguage = async (item?: AccessoryCommonLanguageItem) => {
    commonLanguageStore.addItem(item);
    toast.error(
      "Please finish filling out all items of the new common language"
    );
  };

  return createCommonLanguage;
};

export default useCreateCommonLanguage;
