import React, { Ref, RefObject, useCallback, useLayoutEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { DropdownContent } from "vapi-ui-common";
import {
  GoLiveDateTimeHour,
  GoLiveDateTime,
  goLiveDateTimeMap,
} from "../../../../../utils/dateUtils";
import styles from "./GoLiveDateTimePicker.module.scss";

export interface IGoLiveDateTimePickerList {
  currentTime: GoLiveDateTime;
  onChange: (time: GoLiveDateTime) => void;
  open: boolean;
  closeDropdown: () => void;
}

const GoLiveDateTimePickerList = React.forwardRef(
  (
    { currentTime, onChange, open, closeDropdown }: IGoLiveDateTimePickerList,
    ref: Ref<any>
  ) => {
    const list: GoLiveDateTime[] = [
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_12],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_1],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_2],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_3],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_4],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_5],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_6],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_7],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_8],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_9],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_10],
      goLiveDateTimeMap[GoLiveDateTimeHour.AM_11],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_12],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_1],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_2],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_3],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_4],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_5],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_6],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_7],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_8],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_9],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_10],
      goLiveDateTimeMap[GoLiveDateTimeHour.PM_11],
    ];

    useLayoutEffect(() => {
      if (open && ref) {
        const refObject = ref as RefObject<HTMLDivElement>;

        if (!refObject.current) return;

        const liElements = refObject.current.querySelectorAll(
          'li[role="menuitem"]'
        );
        const targetLi = Array.from(liElements).find(
          (li) => li.textContent === currentTime.text
        );
        targetLi?.scrollIntoView({ behavior: "auto", block: "start" });
      }
    }, [currentTime, open, ref]);

    const CListItem = ({ item }: { item: GoLiveDateTime }) => {
      const onSelect = useCallback(() => {
        onChange(item);
        closeDropdown();
      }, [item]);

      return (
        <li
          key={item.text}
          role="presentation"
          onClick={onSelect}
          onKeyDown={onSelect}
        >
          {item.text}
        </li>
      );
    };

    return (
      <DropdownContent open={open} ref={ref}>
        <ul
          className={styles.dropdownList}
          data-testid="go-live-time-picker-list"
        >
          {list.map((item) => (
            <CListItem item={item} />
          ))}
        </ul>
      </DropdownContent>
    );
  }
);

export default GoLiveDateTimePickerList;
