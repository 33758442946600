import React, { useCallback, useContext } from "react";
import { TableCell } from "vapi-ui-common";
import AmsButton from "../../../../../components/AmsButton";
import CommonLanguageBadge from "../../../../../components/CommonLanguageBadge";
import { AclDocument, RefItem } from "../../../../../gql/generated";
import AccessoryItemVM from "../../../../../models/accessories/AccessoryItemVM";
import { Language } from "../../../../../models/user/user.model";
import useStores from "../../../../../stores/useStores";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import AccessoryRichTextLanguage from "./AccessoryRichTextLanguage";
import styles from "./TitleColumn.module.scss";

interface TitleColumnProps {
  accessories: AccessoryItemVM[];
  accessoryItem: AccessoryItemVM;
  productTypes: RefItem[];
  onSync: (item: AccessoryItemVM, newProductType: string) => Promise<void>;
  sortMode?: boolean;
}

const TitleColumn = ({
  accessories,
  accessoryItem,
  productTypes,
  onSync,
  sortMode,
}: TitleColumnProps) => {
  const {
    selectedLanguages,
    readOnly,
    userDetails,
    draftDetails,
    saveAccessoryItem,
  } = useContext(AccessoriesEntryScreenContext);

  const { onAmsClick } = useContext(AccessoryContext);

  const {
    teamStore: {
      team: { langPermissions, showCommonLanguage, allowSyncAmsAds },
    },
  } = useStores();

  const disabled = !langPermissions[Language.EN]?.canEdit || readOnly;

  const onUnlink = useCallback(() => {
    const acc = accessoryItem;
    acc.comLangId = "";
    saveAccessoryItem(acc, Language.EN);
  }, [saveAccessoryItem, accessoryItem]);

  const sCommonLanguage =
    showCommonLanguage && process.env.REACT_APP_COMMON_LANGUAGE === "true";

  const showSyncAmsAds =
    allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true" &&
    !readOnly &&
    accessoryItem.hasADSUpdates;

  const onLink = useCallback(
    (aclDoc: AclDocument) => {
      const acc = accessoryItem;
      if (!aclDoc || !aclDoc.publishedVersion) {
        return;
      }
      acc.comLangId = aclDoc.sk;
      acc.comLangVersion = aclDoc.publishedVersion;
      acc.hasComLangChanges = false;
      saveAccessoryItem(acc, Language.EN);
    },
    [saveAccessoryItem, accessoryItem]
  );

  const ACLComponent = ({ language }: { language: Language }) => {
    if (language !== Language.EN || !sCommonLanguage) return null;

    return (
      <CommonLanguageBadge
        variables={{ ...userDetails, ...draftDetails }}
        productTypes={productTypes}
        accessoryItem={accessoryItem}
        disabled={disabled}
        readOnly={readOnly || !langPermissions?.[Language.EN]?.canEdit}
        onUnlink={onUnlink}
        onSync={onSync}
        accessories={accessories}
        onLink={onLink}
        sortMode={sortMode}
      />
    );
  };

  return (
    <>
      {selectedLanguages.map((language) => {
        return (
          <TableCell
            largeNat
            key={`${language}-title-column-${accessoryItem.id}`}
            className={styles.titleCell}
          >
            <ACLComponent language={language} />
            {showSyncAmsAds && <AmsButton onClick={onAmsClick} />}
            <AccessoryRichTextLanguage language={language} />
          </TableCell>
        );
      })}
    </>
  );
};

export default TitleColumn;
