import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {
  ActionModal,
  Checkbox,
  DateComponent,
  DescriptionText,
  IconTextButton,
  Notes,
  Popover,
  RTEditor,
  TableCell,
  TableRow,
} from "vapi-ui-common";
import { AccessoryChangeType } from "../../routes/national/components/ChangeLogChanges";
import rteToHtmlComponents from "../../utils/rteToHtmlComponents";
import styles from "./Changelog.module.scss";

interface ChangeLogRowProps {
  description: string;
  changeType: string;
  modifiedBy: string;
  modifiedDate: string;
  notes: string;
  canRevert: boolean;
  onRevert: () => void;
  revertMessage?: JSX.Element;
  renderChanges: JSX.Element;
  apply?: boolean;
  unlink?: boolean;
  id?: string;
  checked?: boolean;
  checkedUnlink?: boolean;
  hideModifiedBy?: boolean;
  hideModifyDate?: boolean;
  hideNotes?: boolean;
  hideRevert?: boolean;
  handleToggleCheckbox?: (id: string, selected: boolean) => void;
  handleToggleCheckboxUnlink?: (id: string, selected: boolean) => void;
}

const ChangeLogRow = ({
  description,
  changeType,
  modifiedBy,
  modifiedDate,
  notes,
  canRevert,
  revertMessage,
  renderChanges,
  onRevert,
  apply, // currently adding checkbox and formatting changes to the row - this is specific for sync modal as of now.
  unlink, // currently adding checkbox and formatting changes to the row - this is specific for sync modal as of now.
  handleToggleCheckbox,
  handleToggleCheckboxUnlink,
  id,
  checked,
  checkedUnlink,
  hideModifiedBy,
  hideModifyDate,
  hideNotes,
  hideRevert,
}: ChangeLogRowProps) => {
  const [openRevertModal, setOpenRevertModal] = useState(false);
  const changeTypeName =
    changeType === AccessoryChangeType.NON_GENUINE ? "AAP" : changeType;

  return (
    <TableRow zebra>
      {apply && (
        <TableCell center className={styles.checkBox}>
          <Checkbox
            id={`addCl-chbox-${id}`}
            checked={checked}
            disabled={false}
            onChange={(e) => {
              if (handleToggleCheckbox && id) {
                handleToggleCheckbox(id, e.currentTarget.checked);
              }
            }}
          />
        </TableCell>
      )}
      {unlink && (
        <TableCell center className={styles.checkBox}>
          <Checkbox
            id={`unlink-chbox-${id}`}
            checked={checkedUnlink}
            disabled={false}
            onChange={(e) => {
              if (handleToggleCheckboxUnlink && id) {
                handleToggleCheckboxUnlink(id, e.currentTarget.checked);
              }
            }}
          />
        </TableCell>
      )}
      <TableCell large>
        <RTEditor disabled value={description || ""} />
      </TableCell>
      <TableCell large>{changeTypeName}</TableCell>
      <TableCell large>
        <DescriptionText size={apply ? "md" : "lg"}>
          {renderChanges}
        </DescriptionText>
      </TableCell>
      {!apply && !hideModifiedBy && <TableCell large>{modifiedBy}</TableCell>}
      {!hideModifyDate && (
        <TableCell large>
          <DateComponent format="MM/DD/YYYY hh:mm:ss A">
            {modifiedDate}
          </DateComponent>
        </TableCell>
      )}
      {!hideNotes && (
        <TableCell large>
          {notes && (
            <Popover
              toggleElement={
                <IconTextButton smallIcon icon="circle" text="Notes" />
              }
              popoverElement={<Notes readOnly notes={notes} />}
              align="right"
            />
          )}
        </TableCell>
      )}
      {!hideRevert && (
        <TableCell large>
          {canRevert && (
            <IconTextButton
              icon="undo"
              text="Revert"
              onClick={() => setOpenRevertModal(true)}
            />
          )}
          <ActionModal
            open={openRevertModal}
            title="Revert Change(s)"
            buttonText="Revert"
            onClose={() => setOpenRevertModal(false)}
            onConfirm={() => onRevert()}
          >
            {revertMessage}
            {!revertMessage && (
              <>
                <>Are you sure you want to revert the change of &quot;</>
                {rteToHtmlComponents(description)}
                <>&quot;?</>
              </>
            )}
          </ActionModal>
        </TableCell>
      )}
    </TableRow>
  );
};

ChangeLogRow.defaultProps = {
  revertMessage: undefined,
  apply: false,
  handleToggleCheckbox: () => {},
  id: "",
  checked: false,
};

export default observer(ChangeLogRow);
