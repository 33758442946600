/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import React, { useState } from "react";
import {
  ActionModal,
  Dropdown,
  DropdownContent,
  TableCell,
  useComponentVisible,
} from "vapi-ui-common";
import rteToHtmlComponents from "../../../../utils/rteToHtmlComponents";
import styles from "./contextMenuCell.module.scss";

interface ContextMenuCellProps {
  description: string;
  itemType: string;
  deleteItem: () => void;
  copyItem?: () => void;
  deleteItemText?: string;
  hide?: boolean;
  disabled?: boolean;
}

const ContextMenuCell = ({
  description,
  itemType,
  deleteItem,
  copyItem,
  deleteItemText,
  hide,
  disabled,
}: ContextMenuCellProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOnDelete = () => {
    setIsComponentVisible(false);
    setShowDeleteModal(true);
  };

  const handleOnCopy = () => {
    setIsComponentVisible(false);
    if (copyItem) {
      copyItem();
    }
  };

  return (
    <TableCell>
      <div ref={ref}>
        <Dropdown>
          <div
            role="presentation"
            onClick={() => setIsComponentVisible(true)}
            onKeyDown={() => setIsComponentVisible(true)}
          >
            <FontAwesomeIcon
              className={cx(styles.dragIcon, isComponentVisible && styles.open)}
              icon={faEllipsisV}
            />
          </div>
          <DropdownContent open={isComponentVisible}>
            {!hide && (
              <div
                role="presentation"
                className={styles.listItem}
                onClick={() => !disabled && handleOnCopy()}
                onKeyDown={() => !disabled && handleOnCopy()}
              >
                Copy
              </div>
            )}

            <div
              role="presentation"
              className={styles.listItem}
              onClick={() => !disabled && handleOnDelete()}
              onKeyDown={() => !disabled && handleOnDelete()}
            >
              {deleteItemText !== undefined ? deleteItemText : "Delete"}
            </div>
          </DropdownContent>
        </Dropdown>

        <ActionModal
          open={showDeleteModal}
          buttonText={deleteItemText !== undefined ? deleteItemText : "Delete"}
          title={`${
            deleteItemText !== undefined ? deleteItemText : "Delete"
          } ${itemType}`}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() => deleteItem()}
        >
          {`Are you sure you want to ${
            deleteItemText?.toLocaleLowerCase() ?? "delete"
          } `}
          &quot;
          {rteToHtmlComponents(description)}
          &quot;?
        </ActionModal>
      </div>
    </TableCell>
  );
};

export default ContextMenuCell;
