import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type AapChangesResponse = {
  __typename?: 'AAPChangesResponse';
  changes?: Maybe<Array<Change>>;
  nationalProductTypes?: Maybe<Array<RefItem>>;
};

export type AccCommonLanguage = {
  __typename?: 'AccCommonLanguage';
  adsId?: Maybe<Scalars['String']>;
  conflicts?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  dioCode?: Maybe<Scalars['Int']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_es?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  hasADSUpdates?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<AclImageItem>>;
  installedDealerCost?: Maybe<Scalars['String']>;
  installPoint?: Maybe<Scalars['String']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  laborTime?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  partsOnlyDealerCost?: Maybe<Scalars['String']>;
  partsOnlyMSRP?: Maybe<Scalars['String']>;
  ppoCode?: Maybe<Scalars['String']>;
  productType: Scalars['String'];
  required?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_es?: Maybe<Scalars['String']>;
  warranty?: Maybe<Scalars['String']>;
  warranty_es?: Maybe<Scalars['String']>;
};

export type AccDocument = {
  __typename?: 'AccDocument';
  brand: Scalars['String'];
  comLangIds?: Maybe<Array<ComLangRef>>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  hasADSUpdates?: Maybe<Scalars['Boolean']>;
  hasGoLiveUpdates?: Maybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  lastNATPublishedDate?: Maybe<Scalars['String']>;
  lastSyncDate?: Maybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  publishedBy?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  region: Scalars['String'];
  seriesId: Scalars['String'];
  sourceVersion?: Maybe<Scalars['Int']>;
  team: Scalars['String'];
};

export type AccDocumentHeader = {
  __typename?: 'AccDocumentHeader';
  brand: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  publishedBy?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type AccDocumentInput = {
  brand: Scalars['String'];
  createdDate?: Maybe<Scalars['String']>;
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  publishedDate?: Maybe<Scalars['String']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  region: Scalars['String'];
  seriesId: Scalars['String'];
  sourceVersion?: Maybe<Scalars['Int']>;
  team: Scalars['String'];
};

export type AccessoriesResponse = {
  __typename?: 'AccessoriesResponse';
  accessories?: Maybe<Array<AccessoryItem>>;
  goLivedDates?: Maybe<Array<Maybe<GoLiveDate>>>;
  header?: Maybe<AccDocument>;
};

export type AccessoryItem = {
  __typename?: 'AccessoryItem';
  abb?: Maybe<Scalars['String']>;
  adsId?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  comLangId?: Maybe<Scalars['String']>;
  comLangVersion?: Maybe<Scalars['Int']>;
  conflicts?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_es?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  gradeMsrp?: Maybe<Array<GradeMsrpItem>>;
  hasAAPSyncChanges?: Maybe<Scalars['Boolean']>;
  hasADSUpdates?: Maybe<Scalars['Boolean']>;
  hasComLangChanges?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  images?: Maybe<Array<Maybe<ImageItem>>>;
  inactive?: Maybe<Scalars['Boolean']>;
  installedDealerCost?: Maybe<Scalars['String']>;
  installPoint?: Maybe<Scalars['String']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  laborTime?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Array<ModelApplicabilityItem>>;
  msrp?: Maybe<Scalars['String']>;
  nationalId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  partsOnlyDealerCost?: Maybe<Scalars['String']>;
  partsOnlyMSRP?: Maybe<Scalars['String']>;
  ppoCode?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  supplier?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_es?: Maybe<Scalars['String']>;
  warranty?: Maybe<Scalars['String']>;
  warranty_es?: Maybe<Scalars['String']>;
};

export type AccessoryItemInput = {
  abb?: Maybe<Scalars['String']>;
  conflicts?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_es?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  gradeMsrp?: Maybe<Array<GradeMsrpItemInput>>;
  hasAAPSyncChanges?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<ImageItemInput>>;
  inactive?: Maybe<Scalars['Boolean']>;
  installedDealerCost?: Maybe<Scalars['String']>;
  installPoint?: Maybe<Scalars['String']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  laborTime?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Array<ModelApplicabilityItemInput>>;
  msrp?: Maybe<Scalars['String']>;
  nationalId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  partsOnlyDealerCost?: Maybe<Scalars['String']>;
  partsOnlyMSRP?: Maybe<Scalars['String']>;
  ppoCode?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_es?: Maybe<Scalars['String']>;
  warranty?: Maybe<Scalars['String']>;
  warranty_es?: Maybe<Scalars['String']>;
};

export type AccessoryItemUpdate = {
  abb?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  comLangId?: Maybe<Scalars['String']>;
  comLangVersion?: Maybe<Scalars['Int']>;
  conflicts?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_es?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  gradeMsrp?: Maybe<Array<GradeMsrpItemInput>>;
  hasAAPSyncChanges?: Maybe<Scalars['Boolean']>;
  hasComLangChanges?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  images?: Maybe<Array<ImageItemInput>>;
  inactive?: Maybe<Scalars['Boolean']>;
  installedDealerCost?: Maybe<Scalars['String']>;
  installPoint?: Maybe<Scalars['String']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  laborTime?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Array<ModelApplicabilityItemInput>>;
  msrp?: Maybe<Scalars['String']>;
  nationalId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  partsOnlyDealerCost?: Maybe<Scalars['String']>;
  partsOnlyMSRP?: Maybe<Scalars['String']>;
  ppoCode?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  supplier?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_es?: Maybe<Scalars['String']>;
  warranty?: Maybe<Scalars['String']>;
  warranty_es?: Maybe<Scalars['String']>;
};

export type AclArchivedDocumentsInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type AclChange = {
  __typename?: 'ACLChange';
  after?: Maybe<Scalars['JSON']>;
  before?: Maybe<Scalars['JSON']>;
  changedItem: AccCommonLanguage;
  changedItemId: Scalars['String'];
  changeType: Scalars['String'];
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
};

export type AclChangeLog = {
  __typename?: 'ACLChangeLog';
  after?: Maybe<Scalars['JSON']>;
  before?: Maybe<Scalars['JSON']>;
  changeType: Scalars['String'];
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
};

export type AclChangesResponse = {
  __typename?: 'ACLChangesResponse';
  changes: Array<AclChange>;
};

export type AclCurrentVersionInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type AclDocument = {
  __typename?: 'ACLDocument';
  accessory: AccCommonLanguage;
  changes: Array<AclChangeLog>;
  data?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isModified?: Maybe<Scalars['Boolean']>;
  pk: Scalars['String'];
  publishedDate?: Maybe<Scalars['String']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  revId: Scalars['String'];
  sk: Scalars['String'];
};

export type AclDocumentInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type AclGradeMsrpItem = {
  __typename?: 'ACLGradeMsrpItem';
  gradeId: Scalars['String'];
  msrp?: Maybe<Scalars['String']>;
};

export type AclGradeMsrpItemInput = {
  gradeId: Scalars['String'];
  msrp?: Maybe<Scalars['String']>;
};

export type AclHeaderInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type AclImageItem = {
  __typename?: 'ACLImageItem';
  image: Scalars['String'];
  isHero: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AclImageItemInput = {
  image: Scalars['String'];
  isHero?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type AclProductTypeItem = {
  __typename?: 'ACLProductTypeItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AclVersionInfo = {
  __typename?: 'ACLVersionInfo';
  changes?: Maybe<Array<AclChangeLog>>;
  lastResetDate?: Maybe<Scalars['String']>;
  publishedBy?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type ArchiveAclDraftInput = {
  brand: Scalars['String'];
  id: Scalars['String'];
  region: Scalars['String'];
  revId: Scalars['String'];
  team: Scalars['String'];
};

export type Change = {
  __typename?: 'Change';
  after?: Maybe<Scalars['String']>;
  afterValue?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  beforeValue?: Maybe<Scalars['String']>;
  changedItem?: Maybe<ChangedItem>;
  changeType: Scalars['String'];
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
};

export type ChangedItem = AccessoryItem | Empty | GoLiveDate;

export type ChangesResponse = {
  __typename?: 'ChangesResponse';
  changes?: Maybe<Array<Change>>;
};

export type ComLangRef = {
  __typename?: 'ComLangRef';
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  publishedVersion: Scalars['Int'];
};

export type CompareAccToAclResponse = {
  __typename?: 'CompareAccToACLResponse';
  aclProductTypes?: Maybe<Array<AclProductTypeItem>>;
  changes?: Maybe<Array<Change>>;
};

export type CopyAccDocInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYearFrom: Scalars['Int'];
  modelYearTo: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CopyAccDocResult = {
  __typename?: 'CopyAccDocResult';
  draft: AccDocument;
};

export type CreateAccDraftInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateAccDraftResult = {
  __typename?: 'CreateAccDraftResult';
  draft: AccDocument;
};

export type CreateAccFromAclInput = {
  brand: Scalars['String'];
  comLangIds: Array<Scalars['String']>;
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateAccFromAclResult = {
  __typename?: 'CreateAccFromACLResult';
  accessories: Array<AccessoryItem>;
  productTypes?: Maybe<Array<RefItem>>;
};

export type CreateAccInput = {
  accessory: AccessoryItemInput;
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type CreateAccResult = {
  __typename?: 'CreateAccResult';
  accessory: AccessoryItem;
};

export type CreateAclDraftInput = {
  accessory: CreateAclItemInput;
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type CreateAclItemInput = {
  conflicts?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  dioCode?: Maybe<Scalars['Int']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_es?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  images?: Maybe<Array<AclImageItemInput>>;
  installedDealerCost?: Maybe<Scalars['String']>;
  installPoint?: Maybe<Scalars['String']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  laborTime?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  partsOnlyDealerCost?: Maybe<Scalars['String']>;
  partsOnlyMSRP?: Maybe<Scalars['String']>;
  ppoCode?: Maybe<Scalars['String']>;
  productType: Scalars['String'];
  required?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_es?: Maybe<Scalars['String']>;
  warranty?: Maybe<Scalars['String']>;
  warranty_es?: Maybe<Scalars['String']>;
};

export type CreateAclProductTypeInput = {
  brand: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type CreateRefItemInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  referenceItemType: Scalars['String'];
  refItem: RefItemInput;
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type CreateRefItemResult = {
  __typename?: 'CreateRefItemResult';
  refItem: RefItem;
};

export type CreateUploadSignUrlInput = {
  filename: Scalars['String'];
};

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  series?: Maybe<Array<SeriesItem>>;
};

export type DeleteAccDraftInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteAccDraftResult = {
  __typename?: 'DeleteAccDraftResult';
  message: Scalars['String'];
};

export type DeleteAccInput = {
  brand: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type DeleteAccResult = {
  __typename?: 'DeleteAccResult';
  message: Scalars['String'];
};

export type DeleteAclDraftInput = {
  brand: Scalars['String'];
  id: Scalars['String'];
  region: Scalars['String'];
  revId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteRefItemInput = {
  brand: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  referenceItemType: Scalars['String'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type DeleteRefItemResult = {
  __typename?: 'DeleteRefItemResult';
  message: Scalars['String'];
  updatedAccessories?: Maybe<Array<AccessoryItem>>;
};

export type DownloadAccDocumentResponse = {
  __typename?: 'DownloadAccDocumentResponse';
  accessories?: Maybe<Array<AccessoryItem>>;
  header: AccDocumentHeader;
  refItems?: Maybe<Array<RefItem>>;
};

export type Empty = {
  __typename?: 'Empty';
  reason?: Maybe<Scalars['String']>;
};

export type GenerateAccReportInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type GenerateAccReportResponse = {
  __typename?: 'GenerateAccReportResponse';
  reportLogResponse: ReportLogResponse;
};

export type GetAclProductTypesInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type GetComLangIdsResponse = {
  __typename?: 'GetComLangIdsResponse';
  comLangIds: Array<ComLangRef>;
};

export type GoLiveDate = {
  __typename?: 'GoLiveDate';
  goLiveDate: Scalars['String'];
  id: Scalars['String'];
  revId: Scalars['String'];
};

export type GoLiveDateUpdate = {
  goLiveDate: Scalars['String'];
  id: Scalars['String'];
  revId?: Maybe<Scalars['String']>;
};

export type GradeMsrpItem = {
  __typename?: 'GradeMsrpItem';
  gradeId: Scalars['String'];
  msrp?: Maybe<Scalars['String']>;
};

export type GradeMsrpItemInput = {
  gradeId: Scalars['String'];
  msrp?: Maybe<Scalars['String']>;
};

export type ImageItem = {
  __typename?: 'ImageItem';
  image?: Maybe<Scalars['String']>;
  isHero?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ImageItemInput = {
  image?: Maybe<Scalars['String']>;
  isHero?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ImageItemResponse = {
  __typename?: 'ImageItemResponse';
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ImagesResponse = {
  __typename?: 'ImagesResponse';
  brand: Scalars['String'];
  images?: Maybe<Array<ImageItemResponse>>;
  region: Scalars['String'];
  team: Scalars['String'];
};

export type INode = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};


export type LatestVersionResponse = {
  __typename?: 'LatestVersionResponse';
  hasDraft: Scalars['Boolean'];
  latestVersion?: Maybe<Scalars['Int']>;
  publishedDate?: Maybe<Scalars['String']>;
};

export type ModelApplicabilityItem = {
  __typename?: 'ModelApplicabilityItem';
  applicabilityText?: Maybe<Scalars['String']>;
  modelId: Scalars['String'];
};

export type ModelApplicabilityItemInput = {
  applicabilityText?: Maybe<Scalars['String']>;
  modelId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  archiveACLDraft: AclDocument;
  copyAccDoc: CopyAccDocResult;
  createAcc: CreateAccResult;
  createAccDraft: CreateAccDraftResult;
  createAccFromACL: CreateAccFromAclResult;
  createACLDraft: AclDocument;
  createACLProductType: Array<AclProductTypeItem>;
  createRefItem: CreateRefItemResult;
  createUploadSignUrl: SignedUploadUrlResponse;
  deleteAcc: DeleteAccResult;
  deleteAccDraft: DeleteAccDraftResult;
  deleteACLDraft: AclDocument;
  deleteRefItem: DeleteRefItemResult;
  generateAccReport: GenerateAccReportResponse;
  publishAccDraft: PublishAccDraftResult;
  publishACLDraft: AclVersionInfo;
  publishDownstreamAccData: PublishDownstreamAccDataResult;
  resetACLDraft: Array<AclDocument>;
  revertAccChange: RevertChangeResult;
  revertACLChange: AclDocument;
  spanishSync: SyncResult;
  updateAAPLastSync: UpdateAapLastSyncResult;
  updateAcc: UpdateAccResult;
  updateAccDocGoLiveDateFlag: UpdateAccDocGoLiveDateFlagResult;
  updateACLDraft: AclDocument;
  updateACLProductType: Array<AclProductTypeItem>;
  updateACLSync: UpdateAclSyncResult;
  updateADSSync: UpdateAdsSyncResult;
  updateGoLiveDate?: Maybe<Array<GoLiveDate>>;
  updateProductTypeSort?: Maybe<UpdateSortResult>;
  updateRefItem: UpdateRefItemResult;
  updateSort?: Maybe<UpdateSortResult>;
  uploadAccDocument: UploadDocResult;
};


export type MutationArchiveAclDraftArgs = {
  input: ArchiveAclDraftInput;
};


export type MutationCopyAccDocArgs = {
  input: CopyAccDocInput;
};


export type MutationCreateAccArgs = {
  input: CreateAccInput;
};


export type MutationCreateAccDraftArgs = {
  input: CreateAccDraftInput;
};


export type MutationCreateAccFromAclArgs = {
  input: CreateAccFromAclInput;
};


export type MutationCreateAclDraftArgs = {
  input: CreateAclDraftInput;
};


export type MutationCreateAclProductTypeArgs = {
  input: CreateAclProductTypeInput;
};


export type MutationCreateRefItemArgs = {
  input: CreateRefItemInput;
};


export type MutationCreateUploadSignUrlArgs = {
  input: CreateUploadSignUrlInput;
};


export type MutationDeleteAccArgs = {
  input: DeleteAccInput;
};


export type MutationDeleteAccDraftArgs = {
  input: DeleteAccDraftInput;
};


export type MutationDeleteAclDraftArgs = {
  input: DeleteAclDraftInput;
};


export type MutationDeleteRefItemArgs = {
  input: DeleteRefItemInput;
};


export type MutationGenerateAccReportArgs = {
  input: GenerateAccReportInput;
};


export type MutationPublishAccDraftArgs = {
  input: PublishAccDraftInput;
};


export type MutationPublishAclDraftArgs = {
  input: PublishAclDraftInput;
};


export type MutationPublishDownstreamAccDataArgs = {
  input: PublishDownstreamAccDataInput;
};


export type MutationResetAclDraftArgs = {
  input: ResetAclDraftInput;
};


export type MutationRevertAccChangeArgs = {
  input: RevertChangeInput;
};


export type MutationRevertAclChangeArgs = {
  input: RevertAclChangeInput;
};


export type MutationSpanishSyncArgs = {
  input: SyncInput;
};


export type MutationUpdateAapLastSyncArgs = {
  input: UpdateAapLastSyncInput;
};


export type MutationUpdateAccArgs = {
  input: UpdateAccInput;
};


export type MutationUpdateAccDocGoLiveDateFlagArgs = {
  input: UpdateAccDocGoLiveDateFlagInput;
};


export type MutationUpdateAclDraftArgs = {
  input: UpdateAclDraftInput;
};


export type MutationUpdateAclProductTypeArgs = {
  input: UpdateAclProductTypeInput;
};


export type MutationUpdateAclSyncArgs = {
  input: UpdateAclSyncInput;
};


export type MutationUpdateAdsSyncArgs = {
  input: UpdateAdsSyncInput;
};


export type MutationUpdateGoLiveDateArgs = {
  input: UpdateGoLiveDateInput;
};


export type MutationUpdateProductTypeSortArgs = {
  input: UpdateSortInput;
};


export type MutationUpdateRefItemArgs = {
  input: UpdateRefItemInput;
};


export type MutationUpdateSortArgs = {
  input: UpdateSortInput;
};


export type MutationUploadAccDocumentArgs = {
  input: UploadDocumemtInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PublishAccDraftInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type PublishAccDraftResult = {
  __typename?: 'PublishAccDraftResult';
  publishedAccDoc: AccDocument;
};

export type PublishAclDraftInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type PublishDownstreamAccDataInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  publishType: Scalars['String'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type PublishDownstreamAccDataResult = {
  __typename?: 'PublishDownstreamAccDataResult';
  seriesItem: SeriesMyItem;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  aapChanges?: Maybe<AapChangesResponse>;
  accessories?: Maybe<AccessoriesResponse>;
  aclArchivedDocuments: Array<AclDocument>;
  aclChanges: AclChangesResponse;
  aclCurrentVersionDocuments: Array<AclDocument>;
  aclDocuments: Array<AclDocument>;
  aclHeader: Scalars['Int'];
  adsAccessory: AccessoryItem;
  adsAccessoryCommonLanguage: AccCommonLanguage;
  changes?: Maybe<ChangesResponse>;
  compareAccToACL?: Maybe<CompareAccToAclResponse>;
  dashboard?: Maybe<DashboardResponse>;
  downloadAccDocument?: Maybe<DownloadAccDocumentResponse>;
  getACLProductTypes: Array<AclProductTypeItem>;
  getComLangIds?: Maybe<GetComLangIdsResponse>;
  images?: Maybe<ImagesResponse>;
  latestVersion?: Maybe<LatestVersionResponse>;
  readReportLog?: Maybe<ReportResponse>;
  refItems?: Maybe<RefItemsResponse>;
  sortList?: Maybe<SortResponse>;
};


export type QueryAapChangesArgs = {
  brand: Scalars['String'];
  id: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};


export type QueryAccessoriesArgs = {
  brand: Scalars['String'];
  getLatestVersion?: Maybe<Scalars['Boolean']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryAclArchivedDocumentsArgs = {
  input: AclArchivedDocumentsInput;
};


export type QueryAclChangesArgs = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};


export type QueryAclCurrentVersionDocumentsArgs = {
  input: AclCurrentVersionInput;
};


export type QueryAclDocumentsArgs = {
  input: AclDocumentInput;
};


export type QueryAclHeaderArgs = {
  input: AclHeaderInput;
};


export type QueryAdsAccessoryArgs = {
  adsPartNumber: Scalars['String'];
};


export type QueryAdsAccessoryCommonLanguageArgs = {
  adsPartNumber: Scalars['String'];
};


export type QueryChangesArgs = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};


export type QueryCompareAccToAclArgs = {
  brand: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};


export type QueryDashboardArgs = {
  brand: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  team: Scalars['String'];
};


export type QueryDownloadAccDocumentArgs = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['Int'];
};


export type QueryGetAclProductTypesArgs = {
  input: GetAclProductTypesInput;
};


export type QueryGetComLangIdsArgs = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};


export type QueryImagesArgs = {
  brand: Scalars['String'];
  region: Scalars['String'];
  searchString: Scalars['String'];
  team: Scalars['String'];
};


export type QueryLatestVersionArgs = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};


export type QueryReadReportLogArgs = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};


export type QueryRefItemsArgs = {
  brand: Scalars['String'];
  getLatestVersion?: Maybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  referenceItemType: Scalars['String'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};


export type QuerySortListArgs = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type RefItem = {
  __typename?: 'RefItem';
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type RefItemInput = {
  name: Scalars['String'];
};

export type RefItemsResponse = {
  __typename?: 'RefItemsResponse';
  header?: Maybe<AccDocument>;
  refItems?: Maybe<Array<RefItem>>;
};

export type RefItemUpdate = {
  id: Scalars['String'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type ReportLogResponse = {
  __typename?: 'ReportLogResponse';
  bucketKey: Scalars['String'];
  fileName: Scalars['String'];
  generatedBy: Scalars['String'];
  generatedDate: Scalars['String'];
  modelYear: Scalars['String'];
  seriesId: Scalars['String'];
  version: Scalars['String'];
};

export type ReportResponse = {
  __typename?: 'ReportResponse';
  reports?: Maybe<Array<Maybe<ReportLogResponse>>>;
};

export type ResetAclDraftInput = {
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type RevertAclChangeInput = {
  brand: Scalars['String'];
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
  to?: Maybe<Scalars['JSON']>;
};

export type RevertChange = {
  changeType?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  to?: Maybe<Scalars['JSON']>;
};

export type RevertChangeInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  revertChange: RevertChange;
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertChangeResult = {
  __typename?: 'RevertChangeResult';
  accessory?: Maybe<AccessoryItem>;
  goLiveDates?: Maybe<Array<Maybe<GoLiveDate>>>;
  message?: Maybe<Scalars['String']>;
  refItem?: Maybe<RefItem>;
};

export type SeriesItem = {
  __typename?: 'SeriesItem';
  seriesId: Scalars['String'];
  seriesName: Scalars['String'];
  years?: Maybe<Array<SeriesMyItem>>;
};

export type SeriesMyItem = {
  __typename?: 'SeriesMYItem';
  comLangIds?: Maybe<Array<ComLangRef>>;
  createdDate?: Maybe<Scalars['String']>;
  englishPublishedDate?: Maybe<Scalars['String']>;
  englishVersion?: Maybe<Scalars['Int']>;
  hasADSUpdates?: Maybe<Scalars['Boolean']>;
  hasGoLiveUpdates?: Maybe<Scalars['Boolean']>;
  isDraft: Scalars['Boolean'];
  lang?: Maybe<Scalars['String']>;
  outOfSync?: Maybe<Scalars['Boolean']>;
  previewDownstreamDate?: Maybe<Scalars['String']>;
  previewDownstreamVersion?: Maybe<Scalars['Int']>;
  publishDownstreamDate?: Maybe<Scalars['String']>;
  publishDownstreamVersion?: Maybe<Scalars['Int']>;
  publishedDate?: Maybe<Scalars['String']>;
  skippedLivePublish?: Maybe<Scalars['Boolean']>;
  sourceVersion?: Maybe<Scalars['Int']>;
  spanishPublishedDate?: Maybe<Scalars['String']>;
  spanishVersion?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type SignedUploadUrlResponse = {
  __typename?: 'SignedUploadUrlResponse';
  fields: Scalars['JSON'];
  url: Scalars['String'];
};

export type SortItem = {
  __typename?: 'SortItem';
  id: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type SortItemInput = {
  id: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type SortResponse = {
  __typename?: 'SortResponse';
  sortList?: Maybe<Array<SortItem>>;
};

export type SyncAccDocumentInput = {
  brand: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type SyncInput = {
  header: SyncAccDocumentInput;
};

export type SyncResult = {
  __typename?: 'SyncResult';
  document: AccDocument;
};

export type UpdateAapLastSyncInput = {
  brand: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateAapLastSyncResult = {
  __typename?: 'UpdateAAPLastSyncResult';
  accessories?: Maybe<Array<AccessoryItem>>;
  lastSyncDate: Scalars['String'];
};

export type UpdateAccDocGoLiveDateFlagInput = {
  brand: Scalars['String'];
  hasGoLiveUpdates: Scalars['Boolean'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  updatePublished?: Maybe<Scalars['Boolean']>;
};

export type UpdateAccDocGoLiveDateFlagResult = {
  __typename?: 'UpdateAccDocGoLiveDateFlagResult';
  accDoc: AccDocument;
};

export type UpdateAccInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  accessory: AccessoryItemUpdate;
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type UpdateAccResult = {
  __typename?: 'UpdateAccResult';
  accessory: AccessoryItem;
};

export type UpdateAclDraftInput = {
  accessory: UpdateAclItemInput;
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateAclItemInput = {
  conflicts?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  dioCode?: Maybe<Scalars['Int']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_es?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images?: Maybe<Array<AclImageItemInput>>;
  installedDealerCost?: Maybe<Scalars['String']>;
  installPoint?: Maybe<Scalars['String']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
  laborTime?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  partsOnlyDealerCost?: Maybe<Scalars['String']>;
  partsOnlyMSRP?: Maybe<Scalars['String']>;
  ppoCode?: Maybe<Scalars['String']>;
  productType: Scalars['String'];
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  supplier?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_es?: Maybe<Scalars['String']>;
  warranty?: Maybe<Scalars['String']>;
  warranty_es?: Maybe<Scalars['String']>;
};

export type UpdateAclProductTypeInput = {
  brand: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateAclSyncInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateAclSyncResult = {
  __typename?: 'UpdateACLSyncResult';
  accessories?: Maybe<Array<AccessoryItem>>;
};

export type UpdateAdsSyncInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateAdsSyncResult = {
  __typename?: 'UpdateADSSyncResult';
  accessories?: Maybe<Array<AccessoryItem>>;
};

export type UpdateGoLiveDateInput = {
  brand: Scalars['String'];
  goLiveDates: Array<GoLiveDateUpdate>;
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateRefItemInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  referenceItemType: Scalars['String'];
  refItem: RefItemUpdate;
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
};

export type UpdateRefItemResult = {
  __typename?: 'UpdateRefItemResult';
  refItem: RefItem;
};

export type UpdateSortInput = {
  brand: Scalars['String'];
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  sortList: Array<SortItemInput>;
  team: Scalars['String'];
};

export type UpdateSortResult = {
  __typename?: 'UpdateSortResult';
  sortList: Array<SortItem>;
};

export type UploadDocResult = {
  __typename?: 'UploadDocResult';
  document: AccDocument;
};

export type UploadDocumemtInput = {
  accessories?: Maybe<Array<AccessoryItemUpdate>>;
  header: AccDocumentInput;
  lang: Scalars['String'];
  refItems?: Maybe<Array<UploadRefItemInput>>;
};

export type UploadRefItemInput = {
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type CreateUploadSignUrlMutationVariables = Exact<{
  filename: Scalars['String'];
}>;


export type CreateUploadSignUrlMutation = (
  { __typename?: 'Mutation' }
  & { createUploadSignUrl: (
    { __typename?: 'SignedUploadUrlResponse' }
    & Pick<SignedUploadUrlResponse, 'url' | 'fields'>
  ) }
);

export type ImageFieldsFragment = (
  { __typename?: 'ImagesResponse' }
  & Pick<ImagesResponse, 'brand' | 'team' | 'region'>
  & { images?: Maybe<Array<(
    { __typename?: 'ImageItemResponse' }
    & Pick<ImageItemResponse, 'name' | 'url'>
  )>> }
);

export type GetImagesQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  searchString: Scalars['String'];
}>;


export type GetImagesQuery = (
  { __typename?: 'Query' }
  & { images?: Maybe<(
    { __typename?: 'ImagesResponse' }
    & ImageFieldsFragment
  )> }
);

export type CompareAccToAclChangesQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  id: Scalars['String'];
}>;


export type CompareAccToAclChangesQuery = (
  { __typename?: 'Query' }
  & { compareAccToACL?: Maybe<(
    { __typename?: 'CompareAccToACLResponse' }
    & { changes?: Maybe<Array<(
      { __typename?: 'Change' }
      & AccessoryChangeLogFieldsFragment
    )>>, aclProductTypes?: Maybe<Array<(
      { __typename?: 'ACLProductTypeItem' }
      & AclProductTypeFieldsFragment
    )>> }
  )> }
);

export type CreateAccessoryMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  accessory: AccessoryItemInput;
}>;


export type CreateAccessoryMutation = (
  { __typename?: 'Mutation' }
  & { createAcc: (
    { __typename?: 'CreateAccResult' }
    & { accessory: (
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    ) }
  ) }
);

export type CreateProductTypeMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { createRefItem: (
    { __typename?: 'CreateRefItemResult' }
    & { refItem: (
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    ) }
  ) }
);

export type DeleteAccessoryMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  id: Scalars['String'];
}>;


export type DeleteAccessoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteAcc: (
    { __typename?: 'DeleteAccResult' }
    & Pick<DeleteAccResult, 'message'>
  ) }
);

export type DeleteProductTypeMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
}>;


export type DeleteProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteRefItem: (
    { __typename?: 'DeleteRefItemResult' }
    & Pick<DeleteRefItemResult, 'message'>
  ) }
);

export type AccessoryFieldsFragment = (
  { __typename?: 'AccessoryItem' }
  & Pick<AccessoryItem, 'id' | 'revId' | 'title' | 'title_es' | 'copy' | 'disclosure' | 'productType' | 'installPoint' | 'ppoCode' | 'abb' | 'supplier' | 'description' | 'description_es' | 'disclaimer' | 'disclaimer_es' | 'msrp' | 'notes' | 'partNumber' | 'partsOnlyDealerCost' | 'partsOnlyMSRP' | 'installedDealerCost' | 'adsId' | 'laborTime' | 'required' | 'conflicts' | 'inactive' | 'isNonGenAccessory' | 'warranty' | 'warranty_es' | 'nationalId' | 'hasAAPSyncChanges' | 'changedAttributes' | 'comLangId' | 'comLangVersion' | 'hasComLangChanges' | 'hasADSUpdates'>
  & { images?: Maybe<Array<Maybe<(
    { __typename?: 'ImageItem' }
    & ImageItemFieldsFragment
  )>>>, modelApplicability?: Maybe<Array<(
    { __typename?: 'ModelApplicabilityItem' }
    & ModelApplicabilityFieldsFragment
  )>>, gradeMsrp?: Maybe<Array<(
    { __typename?: 'GradeMsrpItem' }
    & GradeMsrpFieldsFragment
  )>> }
);

export type ModelApplicabilityFieldsFragment = (
  { __typename?: 'ModelApplicabilityItem' }
  & Pick<ModelApplicabilityItem, 'applicabilityText' | 'modelId'>
);

export type GradeMsrpFieldsFragment = (
  { __typename?: 'GradeMsrpItem' }
  & Pick<GradeMsrpItem, 'gradeId' | 'msrp'>
);

export type ImageItemFieldsFragment = (
  { __typename?: 'ImageItem' }
  & Pick<ImageItem, 'name' | 'image' | 'isHero'>
);

export type ProductTypeFieldsFragment = (
  { __typename?: 'RefItem' }
  & Pick<RefItem, 'id' | 'revId' | 'name'>
);

export type HeaderFieldsFragment = (
  { __typename?: 'AccDocument' }
  & Pick<AccDocument, 'brand' | 'team' | 'region' | 'lang' | 'seriesId' | 'modelYear' | 'createdDate' | 'publishedDate' | 'publishedVersion' | 'lastSyncDate' | 'lastNATPublishedDate' | 'sourceVersion' | 'hasADSUpdates'>
  & { comLangIds?: Maybe<Array<(
    { __typename?: 'ComLangRef' }
    & Pick<ComLangRef, 'id' | 'publishedVersion' | 'isArchived'>
  )>> }
);

export type DownloadHeaderFieldsFragment = (
  { __typename?: 'AccDocumentHeader' }
  & Pick<AccDocumentHeader, 'brand' | 'team' | 'region' | 'lang' | 'seriesId' | 'modelYear' | 'createdDate' | 'publishedDate' | 'publishedVersion'>
);

export type AccessoryChangeLogFieldsFragment = (
  { __typename?: 'Change' }
  & Pick<Change, 'changeType' | 'before' | 'beforeValue' | 'after' | 'afterValue' | 'modifiedBy' | 'modifiedDate'>
  & { changedItem?: Maybe<(
    { __typename?: 'AccessoryItem' }
    & AccessoryFieldsFragment
  ) | { __typename?: 'Empty' } | (
    { __typename?: 'GoLiveDate' }
    & GoLiveDateFieldsFragment
  )> }
);

export type AclProductTypeFieldsFragment = (
  { __typename?: 'ACLProductTypeItem' }
  & Pick<AclProductTypeItem, 'id' | 'name'>
);

export type GoLiveDateFieldsFragment = (
  { __typename?: 'GoLiveDate' }
  & Pick<GoLiveDate, 'goLiveDate' | 'id' | 'revId'>
);

export type GetAapChangesQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  id: Scalars['String'];
}>;


export type GetAapChangesQuery = (
  { __typename?: 'Query' }
  & { aapChanges?: Maybe<(
    { __typename?: 'AAPChangesResponse' }
    & { changes?: Maybe<Array<(
      { __typename?: 'Change' }
      & AccessoryChangeLogFieldsFragment
    )>>, nationalProductTypes?: Maybe<Array<(
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    )>> }
  )> }
);

export type GetAccessoriesQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  getLatestVersion?: Maybe<Scalars['Boolean']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
}>;


export type GetAccessoriesQuery = (
  { __typename?: 'Query' }
  & { accessories?: Maybe<(
    { __typename?: 'AccessoriesResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )>, accessories?: Maybe<Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>>, goLivedDates?: Maybe<Array<Maybe<(
      { __typename?: 'GoLiveDate' }
      & GoLiveDateFieldsFragment
    )>>> }
  )> }
);

export type GetAccessoriesWithProductTypeQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  getLatestVersion?: Maybe<Scalars['Boolean']>;
  isNonGenAccessory?: Maybe<Scalars['Boolean']>;
}>;


export type GetAccessoriesWithProductTypeQuery = (
  { __typename?: 'Query' }
  & { accessories?: Maybe<(
    { __typename?: 'AccessoriesResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )>, accessories?: Maybe<Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>>, goLivedDates?: Maybe<Array<Maybe<(
      { __typename?: 'GoLiveDate' }
      & GoLiveDateFieldsFragment
    )>>> }
  )>, refItems?: Maybe<(
    { __typename?: 'RefItemsResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )>, refItems?: Maybe<Array<(
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    )>> }
  )> }
);

export type GetAdsAccessoryQueryVariables = Exact<{
  adsPartNumber: Scalars['String'];
}>;


export type GetAdsAccessoryQuery = (
  { __typename?: 'Query' }
  & { adsAccessory: (
    { __typename?: 'AccessoryItem' }
    & AccessoryFieldsFragment
  ) }
);

export type GetProductTypesQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
}>;


export type GetProductTypesQuery = (
  { __typename?: 'Query' }
  & { refItems?: Maybe<(
    { __typename?: 'RefItemsResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )>, refItems?: Maybe<Array<(
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    )>> }
  )> }
);

export type UpdateAapLastSyncDateMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type UpdateAapLastSyncDateMutation = (
  { __typename?: 'Mutation' }
  & { updateAAPLastSync: (
    { __typename?: 'UpdateAAPLastSyncResult' }
    & Pick<UpdateAapLastSyncResult, 'lastSyncDate'>
    & { accessories?: Maybe<Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>> }
  ) }
);

export type UpdateAclSyncMutationVariables = Exact<{
  input: UpdateAclSyncInput;
}>;


export type UpdateAclSyncMutation = (
  { __typename?: 'Mutation' }
  & { updateACLSync: (
    { __typename?: 'UpdateACLSyncResult' }
    & { accessories?: Maybe<Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>> }
  ) }
);

export type UpdateAdsSyncMutationVariables = Exact<{
  input: UpdateAdsSyncInput;
}>;


export type UpdateAdsSyncMutation = (
  { __typename?: 'Mutation' }
  & { updateADSSync: (
    { __typename?: 'UpdateADSSyncResult' }
    & { accessories?: Maybe<Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>> }
  ) }
);

export type UpdateAccessoryMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  accessory: AccessoryItemUpdate;
  acceptChanges?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateAccessoryMutation = (
  { __typename?: 'Mutation' }
  & { updateAcc: (
    { __typename?: 'UpdateAccResult' }
    & { accessory: (
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    ) }
  ) }
);

export type UpdateProductTypeMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
  revId: Scalars['String'];
}>;


export type UpdateProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateRefItem: (
    { __typename?: 'UpdateRefItemResult' }
    & { refItem: (
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    ) }
  ) }
);

export type GetAccessoryChangeLogQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
}>;


export type GetAccessoryChangeLogQuery = (
  { __typename?: 'Query' }
  & { changes?: Maybe<(
    { __typename?: 'ChangesResponse' }
    & { changes?: Maybe<Array<(
      { __typename?: 'Change' }
      & AccessoryChangeLogFieldsFragment
    )>> }
  )>, latestVersion?: Maybe<(
    { __typename?: 'LatestVersionResponse' }
    & Pick<LatestVersionResponse, 'latestVersion' | 'hasDraft'>
  )>, accessories?: Maybe<(
    { __typename?: 'AccessoriesResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )> }
  )> }
);

export type GetAccessoryChangeLogWithProductTypeQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
}>;


export type GetAccessoryChangeLogWithProductTypeQuery = (
  { __typename?: 'Query' }
  & { changes?: Maybe<(
    { __typename?: 'ChangesResponse' }
    & { changes?: Maybe<Array<(
      { __typename?: 'Change' }
      & AccessoryChangeLogFieldsFragment
    )>> }
  )>, latestVersion?: Maybe<(
    { __typename?: 'LatestVersionResponse' }
    & Pick<LatestVersionResponse, 'latestVersion' | 'hasDraft'>
  )>, accessories?: Maybe<(
    { __typename?: 'AccessoriesResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )> }
  )>, refItems?: Maybe<(
    { __typename?: 'RefItemsResponse' }
    & { header?: Maybe<(
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    )>, refItems?: Maybe<Array<(
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    )>> }
  )> }
);

export type RevertAccessoryChangeLogMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  changeType: Scalars['String'];
  id: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  to?: Maybe<Scalars['JSON']>;
}>;


export type RevertAccessoryChangeLogMutation = (
  { __typename?: 'Mutation' }
  & { revertAccChange: (
    { __typename?: 'RevertChangeResult' }
    & Pick<RevertChangeResult, 'message'>
    & { accessory?: Maybe<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )> }
  ) }
);

export type AclArchivedDocumentsQueryVariables = Exact<{
  input: AclArchivedDocumentsInput;
}>;


export type AclArchivedDocumentsQuery = (
  { __typename?: 'Query' }
  & { aclArchivedDocuments: Array<(
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  )> }
);

export type AclChangesQueryVariables = Exact<{
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
  version: Scalars['String'];
}>;


export type AclChangesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'aclHeader'>
  & { aclChanges: (
    { __typename?: 'ACLChangesResponse' }
    & { changes: Array<(
      { __typename?: 'ACLChange' }
      & Pick<AclChange, 'changedItemId' | 'changeType' | 'before' | 'after' | 'modifiedBy' | 'modifiedDate'>
      & { changedItem: (
        { __typename?: 'AccCommonLanguage' }
        & AclItemFieldsFragment
      ) }
    )> }
  ), getACLProductTypes: Array<(
    { __typename?: 'ACLProductTypeItem' }
    & Pick<AclProductTypeItem, 'id' | 'name'>
  )> }
);

export type AclCurrentVersionDocumentsQueryVariables = Exact<{
  brand: Scalars['String'];
  region: Scalars['String'];
  team: Scalars['String'];
}>;


export type AclCurrentVersionDocumentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'aclHeader'>
  & { aclCurrentVersionDocuments: Array<(
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  )> }
);

export type AclDocumentsQueryVariables = Exact<{
  input: AclDocumentInput;
}>;


export type AclDocumentsQuery = (
  { __typename?: 'Query' }
  & { aclDocuments: Array<(
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  )> }
);

export type ArchiveAclDraftMutationVariables = Exact<{
  input: ArchiveAclDraftInput;
}>;


export type ArchiveAclDraftMutation = (
  { __typename?: 'Mutation' }
  & { archiveACLDraft: (
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  ) }
);

export type CreateAclDraftMutationVariables = Exact<{
  input: CreateAclDraftInput;
}>;


export type CreateAclDraftMutation = (
  { __typename?: 'Mutation' }
  & { createACLDraft: (
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  ) }
);

export type CreateAclProductTypeMutationVariables = Exact<{
  input: CreateAclProductTypeInput;
}>;


export type CreateAclProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { createACLProductType: Array<(
    { __typename?: 'ACLProductTypeItem' }
    & Pick<AclProductTypeItem, 'id' | 'name'>
  )> }
);

export type CreateAccFromAclMutationVariables = Exact<{
  input: CreateAccFromAclInput;
}>;


export type CreateAccFromAclMutation = (
  { __typename?: 'Mutation' }
  & { createAccFromACL: (
    { __typename?: 'CreateAccFromACLResult' }
    & { accessories: Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>, productTypes?: Maybe<Array<(
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    )>> }
  ) }
);

export type DeleteAclDraftMutationVariables = Exact<{
  input: DeleteAclDraftInput;
}>;


export type DeleteAclDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteACLDraft: (
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  ) }
);

export type AclItemFieldsFragment = (
  { __typename?: 'AccCommonLanguage' }
  & Pick<AccCommonLanguage, 'title' | 'title_es' | 'copy' | 'disclosure' | 'productType' | 'installPoint' | 'ppoCode' | 'dioCode' | 'supplier' | 'description' | 'description_es' | 'disclaimer' | 'disclaimer_es' | 'msrp' | 'notes' | 'partNumber' | 'partsOnlyDealerCost' | 'partsOnlyMSRP' | 'installedDealerCost' | 'adsId' | 'laborTime' | 'required' | 'conflicts' | 'isNonGenAccessory' | 'warranty' | 'warranty_es' | 'hasADSUpdates'>
  & { images?: Maybe<Array<(
    { __typename?: 'ACLImageItem' }
    & Pick<AclImageItem, 'name' | 'image' | 'isHero'>
  )>> }
);

export type AclFieldsFragment = (
  { __typename?: 'ACLDocument' }
  & Pick<AclDocument, 'pk' | 'sk' | 'data' | 'revId' | 'publishedVersion' | 'publishedDate' | 'isModified' | 'isArchived' | 'isDeleted'>
  & { changes: Array<(
    { __typename?: 'ACLChangeLog' }
    & Pick<AclChangeLog, 'changeType' | 'before' | 'after'>
  )>, accessory: (
    { __typename?: 'AccCommonLanguage' }
    & AclItemFieldsFragment
  ) }
);

export type GetAclProductTypesQueryVariables = Exact<{
  input: GetAclProductTypesInput;
}>;


export type GetAclProductTypesQuery = (
  { __typename?: 'Query' }
  & { getACLProductTypes: Array<(
    { __typename?: 'ACLProductTypeItem' }
    & Pick<AclProductTypeItem, 'id' | 'name'>
  )> }
);

export type GetAdsAccessoryCommonLanguageQueryVariables = Exact<{
  adsPartNumber: Scalars['String'];
}>;


export type GetAdsAccessoryCommonLanguageQuery = (
  { __typename?: 'Query' }
  & { adsAccessoryCommonLanguage: (
    { __typename?: 'AccCommonLanguage' }
    & AclItemFieldsFragment
  ) }
);

export type GetCommonLanguageIdsQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
}>;


export type GetCommonLanguageIdsQuery = (
  { __typename?: 'Query' }
  & { getComLangIds?: Maybe<(
    { __typename?: 'GetComLangIdsResponse' }
    & { comLangIds: Array<(
      { __typename?: 'ComLangRef' }
      & Pick<ComLangRef, 'id' | 'publishedVersion' | 'isArchived'>
    )> }
  )> }
);

export type PublishAclDraftMutationVariables = Exact<{
  input: PublishAclDraftInput;
}>;


export type PublishAclDraftMutation = (
  { __typename?: 'Mutation' }
  & { publishACLDraft: (
    { __typename?: 'ACLVersionInfo' }
    & Pick<AclVersionInfo, 'version' | 'publishedBy' | 'publishedDate' | 'lastResetDate'>
    & { changes?: Maybe<Array<(
      { __typename?: 'ACLChangeLog' }
      & Pick<AclChangeLog, 'changeType' | 'before' | 'after'>
    )>> }
  ) }
);

export type ResetAclDraftMutationVariables = Exact<{
  input: ResetAclDraftInput;
}>;


export type ResetAclDraftMutation = (
  { __typename?: 'Mutation' }
  & { resetACLDraft: Array<(
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  )> }
);

export type RevertAclChangeMutationVariables = Exact<{
  input: RevertAclChangeInput;
}>;


export type RevertAclChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertACLChange: (
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  ) }
);

export type UpdateAclDraftMutationVariables = Exact<{
  input: UpdateAclDraftInput;
}>;


export type UpdateAclDraftMutation = (
  { __typename?: 'Mutation' }
  & { updateACLDraft: (
    { __typename?: 'ACLDocument' }
    & AclFieldsFragment
  ) }
);

export type UpdateAclProductTypeMutationVariables = Exact<{
  input: UpdateAclProductTypeInput;
}>;


export type UpdateAclProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateACLProductType: Array<(
    { __typename?: 'ACLProductTypeItem' }
    & Pick<AclProductTypeItem, 'id' | 'name'>
  )> }
);

export type UpdateGoLiveDateMutationVariables = Exact<{
  brand: Scalars['String'];
  goLiveDates: Array<GoLiveDateUpdate>;
  lang: Scalars['String'];
  modelYear: Scalars['Int'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
}>;


export type UpdateGoLiveDateMutation = (
  { __typename?: 'Mutation' }
  & { updateGoLiveDate?: Maybe<Array<(
    { __typename?: 'GoLiveDate' }
    & Pick<GoLiveDate, 'goLiveDate' | 'id' | 'revId'>
  )>> }
);

export type DashboardFieldsFragment = (
  { __typename?: 'SeriesItem' }
  & Pick<SeriesItem, 'seriesId' | 'seriesName'>
  & { years?: Maybe<Array<(
    { __typename?: 'SeriesMYItem' }
    & Pick<SeriesMyItem, 'isDraft' | 'year' | 'version' | 'createdDate' | 'publishedDate' | 'publishDownstreamVersion' | 'publishDownstreamDate' | 'previewDownstreamVersion' | 'previewDownstreamDate' | 'lang' | 'englishPublishedDate' | 'englishVersion' | 'spanishVersion' | 'spanishPublishedDate' | 'sourceVersion' | 'outOfSync' | 'skippedLivePublish' | 'hasADSUpdates'>
    & { comLangIds?: Maybe<Array<(
      { __typename?: 'ComLangRef' }
      & Pick<ComLangRef, 'id' | 'publishedVersion' | 'isArchived'>
    )>> }
  )>> }
);

export type GetDashboardQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
}>;


export type GetDashboardQuery = (
  { __typename?: 'Query' }
  & { dashboard?: Maybe<(
    { __typename?: 'DashboardResponse' }
    & { series?: Maybe<Array<(
      { __typename?: 'SeriesItem' }
      & DashboardFieldsFragment
    )>> }
  )> }
);

export type DownloadAccessoriesQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['Int'];
}>;


export type DownloadAccessoriesQuery = (
  { __typename?: 'Query' }
  & { downloadAccDocument?: Maybe<(
    { __typename?: 'DownloadAccDocumentResponse' }
    & { header: (
      { __typename?: 'AccDocumentHeader' }
      & DownloadHeaderFieldsFragment
    ), accessories?: Maybe<Array<(
      { __typename?: 'AccessoryItem' }
      & AccessoryFieldsFragment
    )>>, refItems?: Maybe<Array<(
      { __typename?: 'RefItem' }
      & ProductTypeFieldsFragment
    )>> }
  )> }
);

export type CopyAccessoryDocumentMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYearFrom: Scalars['Int'];
  modelYearTo: Scalars['Int'];
}>;


export type CopyAccessoryDocumentMutation = (
  { __typename?: 'Mutation' }
  & { copyAccDoc: (
    { __typename?: 'CopyAccDocResult' }
    & { draft: (
      { __typename?: 'AccDocument' }
      & DraftFieldsFragment
    ) }
  ) }
);

export type CreateAccessoryDraftMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type CreateAccessoryDraftMutation = (
  { __typename?: 'Mutation' }
  & { createAccDraft: (
    { __typename?: 'CreateAccDraftResult' }
    & { draft: (
      { __typename?: 'AccDocument' }
      & DraftFieldsFragment
    ) }
  ) }
);

export type DeleteAccessoryDraftMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type DeleteAccessoryDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccDraft: (
    { __typename?: 'DeleteAccDraftResult' }
    & Pick<DeleteAccDraftResult, 'message'>
  ) }
);

export type DraftFieldsFragment = (
  { __typename?: 'AccDocument' }
  & Pick<AccDocument, 'brand' | 'team' | 'region' | 'lang' | 'seriesId' | 'modelYear' | 'createdDate'>
  & { comLangIds?: Maybe<Array<(
    { __typename?: 'ComLangRef' }
    & Pick<ComLangRef, 'id' | 'publishedVersion' | 'isArchived'>
  )>> }
);

export type PublishAccessoryDraftMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type PublishAccessoryDraftMutation = (
  { __typename?: 'Mutation' }
  & { publishAccDraft: (
    { __typename?: 'PublishAccDraftResult' }
    & { publishedAccDoc: (
      { __typename?: 'AccDocument' }
      & DraftFieldsFragment
    ) }
  ) }
);

export type SeriesFieldsFragment = (
  { __typename?: 'SeriesMYItem' }
  & Pick<SeriesMyItem, 'isDraft' | 'year' | 'version' | 'createdDate' | 'publishedDate' | 'publishDownstreamVersion' | 'publishDownstreamDate' | 'previewDownstreamVersion' | 'previewDownstreamDate' | 'outOfSync' | 'skippedLivePublish'>
);

export type PublishDownstreamAccessoryDataMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  publishType: Scalars['String'];
}>;


export type PublishDownstreamAccessoryDataMutation = (
  { __typename?: 'Mutation' }
  & { publishDownstreamAccData: (
    { __typename?: 'PublishDownstreamAccDataResult' }
    & { seriesItem: (
      { __typename?: 'SeriesMYItem' }
      & SeriesFieldsFragment
    ) }
  ) }
);

export type ReportLogFieldsFragment = (
  { __typename?: 'ReportLogResponse' }
  & Pick<ReportLogResponse, 'bucketKey' | 'fileName' | 'version' | 'seriesId' | 'modelYear' | 'generatedBy' | 'generatedDate'>
);

export type GenerateReportMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  version: Scalars['String'];
}>;


export type GenerateReportMutation = (
  { __typename?: 'Mutation' }
  & { generateAccReport: (
    { __typename?: 'GenerateAccReportResponse' }
    & { reportLogResponse: (
      { __typename?: 'ReportLogResponse' }
      & ReportLogFieldsFragment
    ) }
  ) }
);

export type GetGeneratedReportsQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
}>;


export type GetGeneratedReportsQuery = (
  { __typename?: 'Query' }
  & { readReportLog?: Maybe<(
    { __typename?: 'ReportResponse' }
    & { reports?: Maybe<Array<Maybe<(
      { __typename?: 'ReportLogResponse' }
      & ReportLogFieldsFragment
    )>>> }
  )> }
);

export type GetLatestVersionQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type GetLatestVersionQuery = (
  { __typename?: 'Query' }
  & { latestVersion?: Maybe<(
    { __typename?: 'LatestVersionResponse' }
    & Pick<LatestVersionResponse, 'latestVersion' | 'hasDraft' | 'publishedDate'>
  )> }
);

export type UpdateProductTypeSortMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  sortList: Array<SortItemInput>;
}>;


export type UpdateProductTypeSortMutation = (
  { __typename?: 'Mutation' }
  & { updateProductTypeSort?: Maybe<(
    { __typename?: 'UpdateSortResult' }
    & { sortList: Array<(
      { __typename?: 'SortItem' }
      & Pick<SortItem, 'id' | 'sortOrder'>
    )> }
  )> }
);

export type UpdateSortMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  lang: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  sortList: Array<SortItemInput>;
}>;


export type UpdateSortMutation = (
  { __typename?: 'Mutation' }
  & { updateSort?: Maybe<(
    { __typename?: 'UpdateSortResult' }
    & { sortList: Array<(
      { __typename?: 'SortItem' }
      & Pick<SortItem, 'id' | 'sortOrder'>
    )> }
  )> }
);

export type UpdateSpanishSyncMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  region: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type UpdateSpanishSyncMutation = (
  { __typename?: 'Mutation' }
  & { spanishSync: (
    { __typename?: 'SyncResult' }
    & { document: (
      { __typename?: 'AccDocument' }
      & HeaderFieldsFragment
    ) }
  ) }
);

export type AccDocFieldsFragment = (
  { __typename?: 'AccDocument' }
  & Pick<AccDocument, 'brand' | 'team' | 'region' | 'lang' | 'seriesId' | 'modelYear' | 'createdDate' | 'publishedDate' | 'publishedVersion' | 'publishedBy' | 'createdBy'>
  & { comLangIds?: Maybe<Array<(
    { __typename?: 'ComLangRef' }
    & Pick<ComLangRef, 'id' | 'publishedVersion' | 'isArchived'>
  )>> }
);

export type UploadAccessoriesMutationVariables = Exact<{
  lang: Scalars['String'];
  header: AccDocumentInput;
  accessories: Array<AccessoryItemUpdate>;
  refItems: Array<UploadRefItemInput>;
}>;


export type UploadAccessoriesMutation = (
  { __typename?: 'Mutation' }
  & { uploadAccDocument: (
    { __typename?: 'UploadDocResult' }
    & { document: (
      { __typename?: 'AccDocument' }
      & AccDocFieldsFragment
    ) }
  ) }
);

export const ImageFieldsFragmentDoc = gql`
    fragment ImageFields on ImagesResponse {
  brand
  team
  region
  images {
    name
    url
  }
}
    `;
export const ProductTypeFieldsFragmentDoc = gql`
    fragment ProductTypeFields on RefItem {
  id
  revId
  name
}
    `;
export const HeaderFieldsFragmentDoc = gql`
    fragment HeaderFields on AccDocument {
  brand
  team
  region
  lang
  seriesId
  modelYear
  createdDate
  publishedDate
  publishedVersion
  lastSyncDate
  lastNATPublishedDate
  sourceVersion
  comLangIds {
    id
    publishedVersion
    isArchived
  }
  hasADSUpdates
}
    `;
export const DownloadHeaderFieldsFragmentDoc = gql`
    fragment DownloadHeaderFields on AccDocumentHeader {
  brand
  team
  region
  lang
  seriesId
  modelYear
  createdDate
  publishedDate
  publishedVersion
}
    `;
export const ImageItemFieldsFragmentDoc = gql`
    fragment ImageItemFields on ImageItem {
  name
  image
  isHero
}
    `;
export const ModelApplicabilityFieldsFragmentDoc = gql`
    fragment ModelApplicabilityFields on ModelApplicabilityItem {
  applicabilityText
  modelId
}
    `;
export const GradeMsrpFieldsFragmentDoc = gql`
    fragment GradeMsrpFields on GradeMsrpItem {
  gradeId
  msrp
}
    `;
export const AccessoryFieldsFragmentDoc = gql`
    fragment AccessoryFields on AccessoryItem {
  id
  revId
  title
  title_es
  copy
  disclosure
  productType
  installPoint
  ppoCode
  abb
  images {
    ...ImageItemFields
  }
  supplier
  description
  description_es
  disclaimer
  disclaimer_es
  msrp
  notes
  partNumber
  partsOnlyDealerCost
  partsOnlyMSRP
  installedDealerCost
  adsId
  laborTime
  required
  conflicts
  inactive
  modelApplicability {
    ...ModelApplicabilityFields
  }
  gradeMsrp {
    ...GradeMsrpFields
  }
  isNonGenAccessory
  warranty
  warranty_es
  nationalId
  hasAAPSyncChanges
  changedAttributes
  comLangId
  comLangVersion
  hasComLangChanges
  hasADSUpdates
}
    ${ImageItemFieldsFragmentDoc}
${ModelApplicabilityFieldsFragmentDoc}
${GradeMsrpFieldsFragmentDoc}`;
export const GoLiveDateFieldsFragmentDoc = gql`
    fragment GoLiveDateFields on GoLiveDate {
  goLiveDate
  id
  revId
}
    `;
export const AccessoryChangeLogFieldsFragmentDoc = gql`
    fragment AccessoryChangeLogFields on Change {
  changeType
  before
  beforeValue
  after
  afterValue
  modifiedBy
  modifiedDate
  changedItem {
    ...AccessoryFields
    ...GoLiveDateFields
  }
}
    ${AccessoryFieldsFragmentDoc}
${GoLiveDateFieldsFragmentDoc}`;
export const AclProductTypeFieldsFragmentDoc = gql`
    fragment ACLProductTypeFields on ACLProductTypeItem {
  id
  name
}
    `;
export const AclItemFieldsFragmentDoc = gql`
    fragment ACLItemFields on AccCommonLanguage {
  title
  title_es
  copy
  disclosure
  productType
  installPoint
  ppoCode
  dioCode
  images {
    name
    image
    isHero
  }
  supplier
  description
  description_es
  disclaimer
  disclaimer_es
  msrp
  notes
  partNumber
  partsOnlyDealerCost
  partsOnlyMSRP
  installedDealerCost
  adsId
  laborTime
  required
  conflicts
  isNonGenAccessory
  warranty
  warranty_es
  hasADSUpdates
}
    `;
export const AclFieldsFragmentDoc = gql`
    fragment ACLFields on ACLDocument {
  pk
  sk
  data
  revId
  publishedVersion
  publishedDate
  isModified
  isArchived
  isDeleted
  changes {
    changeType
    before
    after
  }
  accessory {
    ...ACLItemFields
  }
}
    ${AclItemFieldsFragmentDoc}`;
export const DashboardFieldsFragmentDoc = gql`
    fragment DashboardFields on SeriesItem {
  seriesId
  seriesName
  years {
    isDraft
    year
    version
    createdDate
    publishedDate
    publishDownstreamVersion
    publishDownstreamDate
    previewDownstreamVersion
    previewDownstreamDate
    lang
    englishPublishedDate
    englishVersion
    spanishVersion
    spanishPublishedDate
    sourceVersion
    outOfSync
    skippedLivePublish
    comLangIds {
      id
      publishedVersion
      isArchived
    }
    hasADSUpdates
  }
}
    `;
export const DraftFieldsFragmentDoc = gql`
    fragment DraftFields on AccDocument {
  brand
  team
  region
  lang
  seriesId
  modelYear
  createdDate
  comLangIds {
    id
    publishedVersion
    isArchived
  }
}
    `;
export const SeriesFieldsFragmentDoc = gql`
    fragment SeriesFields on SeriesMYItem {
  isDraft
  year
  version
  createdDate
  publishedDate
  publishDownstreamVersion
  publishDownstreamDate
  previewDownstreamVersion
  previewDownstreamDate
  outOfSync
  skippedLivePublish
}
    `;
export const ReportLogFieldsFragmentDoc = gql`
    fragment ReportLogFields on ReportLogResponse {
  bucketKey
  fileName
  version
  seriesId
  modelYear
  generatedBy
  generatedDate
}
    `;
export const AccDocFieldsFragmentDoc = gql`
    fragment AccDocFields on AccDocument {
  brand
  team
  region
  lang
  seriesId
  modelYear
  createdDate
  publishedDate
  publishedVersion
  publishedBy
  createdBy
  comLangIds {
    id
    publishedVersion
    isArchived
  }
}
    `;
export const CreateUploadSignUrlDocument = gql`
    mutation createUploadSignUrl($filename: String!) {
  createUploadSignUrl(input: {filename: $filename}) {
    url
    fields
  }
}
    `;
export type CreateUploadSignUrlMutationFn = Apollo.MutationFunction<CreateUploadSignUrlMutation, CreateUploadSignUrlMutationVariables>;

/**
 * __useCreateUploadSignUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadSignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadSignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadSignUrlMutation, { data, loading, error }] = useCreateUploadSignUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useCreateUploadSignUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadSignUrlMutation, CreateUploadSignUrlMutationVariables>) {
        return Apollo.useMutation<CreateUploadSignUrlMutation, CreateUploadSignUrlMutationVariables>(CreateUploadSignUrlDocument, baseOptions);
      }
export type CreateUploadSignUrlMutationHookResult = ReturnType<typeof useCreateUploadSignUrlMutation>;
export type CreateUploadSignUrlMutationResult = Apollo.MutationResult<CreateUploadSignUrlMutation>;
export type CreateUploadSignUrlMutationOptions = Apollo.BaseMutationOptions<CreateUploadSignUrlMutation, CreateUploadSignUrlMutationVariables>;
export const GetImagesDocument = gql`
    query getImages($brand: String!, $team: String!, $region: String!, $searchString: String!) {
  images(brand: $brand, team: $team, region: $region, searchString: $searchString) {
    ...ImageFields
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetImagesQuery__
 *
 * To run a query within a React component, call `useGetImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImagesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetImagesQuery(baseOptions: Apollo.QueryHookOptions<GetImagesQuery, GetImagesQueryVariables>) {
        return Apollo.useQuery<GetImagesQuery, GetImagesQueryVariables>(GetImagesDocument, baseOptions);
      }
export function useGetImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImagesQuery, GetImagesQueryVariables>) {
          return Apollo.useLazyQuery<GetImagesQuery, GetImagesQueryVariables>(GetImagesDocument, baseOptions);
        }
export type GetImagesQueryHookResult = ReturnType<typeof useGetImagesQuery>;
export type GetImagesLazyQueryHookResult = ReturnType<typeof useGetImagesLazyQuery>;
export type GetImagesQueryResult = Apollo.QueryResult<GetImagesQuery, GetImagesQueryVariables>;
export const CompareAccToAclChangesDocument = gql`
    query compareAccToACLChanges($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $id: String!) {
  compareAccToACL(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    id: $id
  ) {
    changes {
      ...AccessoryChangeLogFields
    }
    aclProductTypes {
      ...ACLProductTypeFields
    }
  }
}
    ${AccessoryChangeLogFieldsFragmentDoc}
${AclProductTypeFieldsFragmentDoc}`;

/**
 * __useCompareAccToAclChangesQuery__
 *
 * To run a query within a React component, call `useCompareAccToAclChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareAccToAclChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareAccToAclChangesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompareAccToAclChangesQuery(baseOptions: Apollo.QueryHookOptions<CompareAccToAclChangesQuery, CompareAccToAclChangesQueryVariables>) {
        return Apollo.useQuery<CompareAccToAclChangesQuery, CompareAccToAclChangesQueryVariables>(CompareAccToAclChangesDocument, baseOptions);
      }
export function useCompareAccToAclChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareAccToAclChangesQuery, CompareAccToAclChangesQueryVariables>) {
          return Apollo.useLazyQuery<CompareAccToAclChangesQuery, CompareAccToAclChangesQueryVariables>(CompareAccToAclChangesDocument, baseOptions);
        }
export type CompareAccToAclChangesQueryHookResult = ReturnType<typeof useCompareAccToAclChangesQuery>;
export type CompareAccToAclChangesLazyQueryHookResult = ReturnType<typeof useCompareAccToAclChangesLazyQuery>;
export type CompareAccToAclChangesQueryResult = Apollo.QueryResult<CompareAccToAclChangesQuery, CompareAccToAclChangesQueryVariables>;
export const CreateAccessoryDocument = gql`
    mutation createAccessory($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $accessory: AccessoryItemInput!) {
  createAcc(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version, accessory: $accessory}
  ) {
    accessory {
      ...AccessoryFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}`;
export type CreateAccessoryMutationFn = Apollo.MutationFunction<CreateAccessoryMutation, CreateAccessoryMutationVariables>;

/**
 * __useCreateAccessoryMutation__
 *
 * To run a mutation, you first call `useCreateAccessoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessoryMutation, { data, loading, error }] = useCreateAccessoryMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      accessory: // value for 'accessory'
 *   },
 * });
 */
export function useCreateAccessoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessoryMutation, CreateAccessoryMutationVariables>) {
        return Apollo.useMutation<CreateAccessoryMutation, CreateAccessoryMutationVariables>(CreateAccessoryDocument, baseOptions);
      }
export type CreateAccessoryMutationHookResult = ReturnType<typeof useCreateAccessoryMutation>;
export type CreateAccessoryMutationResult = Apollo.MutationResult<CreateAccessoryMutation>;
export type CreateAccessoryMutationOptions = Apollo.BaseMutationOptions<CreateAccessoryMutation, CreateAccessoryMutationVariables>;
export const CreateProductTypeDocument = gql`
    mutation createProductType($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $name: String!) {
  createRefItem(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version, referenceItemType: "productTypeRefItems", refItem: {name: $name}}
  ) {
    refItem {
      ...ProductTypeFields
    }
  }
}
    ${ProductTypeFieldsFragmentDoc}`;
export type CreateProductTypeMutationFn = Apollo.MutationFunction<CreateProductTypeMutation, CreateProductTypeMutationVariables>;

/**
 * __useCreateProductTypeMutation__
 *
 * To run a mutation, you first call `useCreateProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductTypeMutation, { data, loading, error }] = useCreateProductTypeMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductTypeMutation, CreateProductTypeMutationVariables>) {
        return Apollo.useMutation<CreateProductTypeMutation, CreateProductTypeMutationVariables>(CreateProductTypeDocument, baseOptions);
      }
export type CreateProductTypeMutationHookResult = ReturnType<typeof useCreateProductTypeMutation>;
export type CreateProductTypeMutationResult = Apollo.MutationResult<CreateProductTypeMutation>;
export type CreateProductTypeMutationOptions = Apollo.BaseMutationOptions<CreateProductTypeMutation, CreateProductTypeMutationVariables>;
export const DeleteAccessoryDocument = gql`
    mutation deleteAccessory($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $id: String!) {
  deleteAcc(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version, id: $id}
  ) {
    message
  }
}
    `;
export type DeleteAccessoryMutationFn = Apollo.MutationFunction<DeleteAccessoryMutation, DeleteAccessoryMutationVariables>;

/**
 * __useDeleteAccessoryMutation__
 *
 * To run a mutation, you first call `useDeleteAccessoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessoryMutation, { data, loading, error }] = useDeleteAccessoryMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccessoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccessoryMutation, DeleteAccessoryMutationVariables>) {
        return Apollo.useMutation<DeleteAccessoryMutation, DeleteAccessoryMutationVariables>(DeleteAccessoryDocument, baseOptions);
      }
export type DeleteAccessoryMutationHookResult = ReturnType<typeof useDeleteAccessoryMutation>;
export type DeleteAccessoryMutationResult = Apollo.MutationResult<DeleteAccessoryMutation>;
export type DeleteAccessoryMutationOptions = Apollo.BaseMutationOptions<DeleteAccessoryMutation, DeleteAccessoryMutationVariables>;
export const DeleteProductTypeDocument = gql`
    mutation deleteProductType($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $name: String!, $id: String!) {
  deleteRefItem(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version, referenceItemType: "productTypeRefItems", id: $id}
  ) {
    message
  }
}
    `;
export type DeleteProductTypeMutationFn = Apollo.MutationFunction<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>;

/**
 * __useDeleteProductTypeMutation__
 *
 * To run a mutation, you first call `useDeleteProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductTypeMutation, { data, loading, error }] = useDeleteProductTypeMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>) {
        return Apollo.useMutation<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>(DeleteProductTypeDocument, baseOptions);
      }
export type DeleteProductTypeMutationHookResult = ReturnType<typeof useDeleteProductTypeMutation>;
export type DeleteProductTypeMutationResult = Apollo.MutationResult<DeleteProductTypeMutation>;
export type DeleteProductTypeMutationOptions = Apollo.BaseMutationOptions<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>;
export const GetAapChangesDocument = gql`
    query getAAPChanges($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $id: String!) {
  aapChanges(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    id: $id
  ) {
    changes {
      ...AccessoryChangeLogFields
    }
    nationalProductTypes {
      ...ProductTypeFields
    }
  }
}
    ${AccessoryChangeLogFieldsFragmentDoc}
${ProductTypeFieldsFragmentDoc}`;

/**
 * __useGetAapChangesQuery__
 *
 * To run a query within a React component, call `useGetAapChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAapChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAapChangesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAapChangesQuery(baseOptions: Apollo.QueryHookOptions<GetAapChangesQuery, GetAapChangesQueryVariables>) {
        return Apollo.useQuery<GetAapChangesQuery, GetAapChangesQueryVariables>(GetAapChangesDocument, baseOptions);
      }
export function useGetAapChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAapChangesQuery, GetAapChangesQueryVariables>) {
          return Apollo.useLazyQuery<GetAapChangesQuery, GetAapChangesQueryVariables>(GetAapChangesDocument, baseOptions);
        }
export type GetAapChangesQueryHookResult = ReturnType<typeof useGetAapChangesQuery>;
export type GetAapChangesLazyQueryHookResult = ReturnType<typeof useGetAapChangesLazyQuery>;
export type GetAapChangesQueryResult = Apollo.QueryResult<GetAapChangesQuery, GetAapChangesQueryVariables>;
export const GetAccessoriesDocument = gql`
    query getAccessories($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $getLatestVersion: Boolean, $isNonGenAccessory: Boolean) {
  accessories(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
    getLatestVersion: $getLatestVersion
    isNonGenAccessory: $isNonGenAccessory
  ) {
    header {
      ...HeaderFields
    }
    accessories {
      ...AccessoryFields
    }
    goLivedDates {
      ...GoLiveDateFields
    }
  }
}
    ${HeaderFieldsFragmentDoc}
${AccessoryFieldsFragmentDoc}
${GoLiveDateFieldsFragmentDoc}`;

/**
 * __useGetAccessoriesQuery__
 *
 * To run a query within a React component, call `useGetAccessoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoriesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      getLatestVersion: // value for 'getLatestVersion'
 *      isNonGenAccessory: // value for 'isNonGenAccessory'
 *   },
 * });
 */
export function useGetAccessoriesQuery(baseOptions: Apollo.QueryHookOptions<GetAccessoriesQuery, GetAccessoriesQueryVariables>) {
        return Apollo.useQuery<GetAccessoriesQuery, GetAccessoriesQueryVariables>(GetAccessoriesDocument, baseOptions);
      }
export function useGetAccessoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoriesQuery, GetAccessoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetAccessoriesQuery, GetAccessoriesQueryVariables>(GetAccessoriesDocument, baseOptions);
        }
export type GetAccessoriesQueryHookResult = ReturnType<typeof useGetAccessoriesQuery>;
export type GetAccessoriesLazyQueryHookResult = ReturnType<typeof useGetAccessoriesLazyQuery>;
export type GetAccessoriesQueryResult = Apollo.QueryResult<GetAccessoriesQuery, GetAccessoriesQueryVariables>;
export const GetAccessoriesWithProductTypeDocument = gql`
    query getAccessoriesWithProductType($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $getLatestVersion: Boolean, $isNonGenAccessory: Boolean) {
  accessories(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
    getLatestVersion: $getLatestVersion
    isNonGenAccessory: $isNonGenAccessory
  ) {
    header {
      ...HeaderFields
    }
    accessories {
      ...AccessoryFields
    }
    goLivedDates {
      ...GoLiveDateFields
    }
  }
  refItems(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
    referenceItemType: "productTypeRefItems"
    getLatestVersion: $getLatestVersion
  ) {
    header {
      ...HeaderFields
    }
    refItems {
      ...ProductTypeFields
    }
  }
}
    ${HeaderFieldsFragmentDoc}
${AccessoryFieldsFragmentDoc}
${GoLiveDateFieldsFragmentDoc}
${ProductTypeFieldsFragmentDoc}`;

/**
 * __useGetAccessoriesWithProductTypeQuery__
 *
 * To run a query within a React component, call `useGetAccessoriesWithProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoriesWithProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoriesWithProductTypeQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      getLatestVersion: // value for 'getLatestVersion'
 *      isNonGenAccessory: // value for 'isNonGenAccessory'
 *   },
 * });
 */
export function useGetAccessoriesWithProductTypeQuery(baseOptions: Apollo.QueryHookOptions<GetAccessoriesWithProductTypeQuery, GetAccessoriesWithProductTypeQueryVariables>) {
        return Apollo.useQuery<GetAccessoriesWithProductTypeQuery, GetAccessoriesWithProductTypeQueryVariables>(GetAccessoriesWithProductTypeDocument, baseOptions);
      }
export function useGetAccessoriesWithProductTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoriesWithProductTypeQuery, GetAccessoriesWithProductTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetAccessoriesWithProductTypeQuery, GetAccessoriesWithProductTypeQueryVariables>(GetAccessoriesWithProductTypeDocument, baseOptions);
        }
export type GetAccessoriesWithProductTypeQueryHookResult = ReturnType<typeof useGetAccessoriesWithProductTypeQuery>;
export type GetAccessoriesWithProductTypeLazyQueryHookResult = ReturnType<typeof useGetAccessoriesWithProductTypeLazyQuery>;
export type GetAccessoriesWithProductTypeQueryResult = Apollo.QueryResult<GetAccessoriesWithProductTypeQuery, GetAccessoriesWithProductTypeQueryVariables>;
export const GetAdsAccessoryDocument = gql`
    query getAdsAccessory($adsPartNumber: String!) {
  adsAccessory(adsPartNumber: $adsPartNumber) {
    ...AccessoryFields
  }
}
    ${AccessoryFieldsFragmentDoc}`;

/**
 * __useGetAdsAccessoryQuery__
 *
 * To run a query within a React component, call `useGetAdsAccessoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsAccessoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsAccessoryQuery({
 *   variables: {
 *      adsPartNumber: // value for 'adsPartNumber'
 *   },
 * });
 */
export function useGetAdsAccessoryQuery(baseOptions: Apollo.QueryHookOptions<GetAdsAccessoryQuery, GetAdsAccessoryQueryVariables>) {
        return Apollo.useQuery<GetAdsAccessoryQuery, GetAdsAccessoryQueryVariables>(GetAdsAccessoryDocument, baseOptions);
      }
export function useGetAdsAccessoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdsAccessoryQuery, GetAdsAccessoryQueryVariables>) {
          return Apollo.useLazyQuery<GetAdsAccessoryQuery, GetAdsAccessoryQueryVariables>(GetAdsAccessoryDocument, baseOptions);
        }
export type GetAdsAccessoryQueryHookResult = ReturnType<typeof useGetAdsAccessoryQuery>;
export type GetAdsAccessoryLazyQueryHookResult = ReturnType<typeof useGetAdsAccessoryLazyQuery>;
export type GetAdsAccessoryQueryResult = Apollo.QueryResult<GetAdsAccessoryQuery, GetAdsAccessoryQueryVariables>;
export const GetProductTypesDocument = gql`
    query getProductTypes($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!) {
  refItems(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
    referenceItemType: "productTypeRefItems"
  ) {
    header {
      ...HeaderFields
    }
    refItems {
      ...ProductTypeFields
    }
  }
}
    ${HeaderFieldsFragmentDoc}
${ProductTypeFieldsFragmentDoc}`;

/**
 * __useGetProductTypesQuery__
 *
 * To run a query within a React component, call `useGetProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetProductTypesQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
        return Apollo.useQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, baseOptions);
      }
export function useGetProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, baseOptions);
        }
export type GetProductTypesQueryHookResult = ReturnType<typeof useGetProductTypesQuery>;
export type GetProductTypesLazyQueryHookResult = ReturnType<typeof useGetProductTypesLazyQuery>;
export type GetProductTypesQueryResult = Apollo.QueryResult<GetProductTypesQuery, GetProductTypesQueryVariables>;
export const UpdateAapLastSyncDateDocument = gql`
    mutation updateAAPLastSyncDate($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!) {
  updateAAPLastSync(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear}
  ) {
    lastSyncDate
    accessories {
      ...AccessoryFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}`;
export type UpdateAapLastSyncDateMutationFn = Apollo.MutationFunction<UpdateAapLastSyncDateMutation, UpdateAapLastSyncDateMutationVariables>;

/**
 * __useUpdateAapLastSyncDateMutation__
 *
 * To run a mutation, you first call `useUpdateAapLastSyncDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAapLastSyncDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAapLastSyncDateMutation, { data, loading, error }] = useUpdateAapLastSyncDateMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useUpdateAapLastSyncDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAapLastSyncDateMutation, UpdateAapLastSyncDateMutationVariables>) {
        return Apollo.useMutation<UpdateAapLastSyncDateMutation, UpdateAapLastSyncDateMutationVariables>(UpdateAapLastSyncDateDocument, baseOptions);
      }
export type UpdateAapLastSyncDateMutationHookResult = ReturnType<typeof useUpdateAapLastSyncDateMutation>;
export type UpdateAapLastSyncDateMutationResult = Apollo.MutationResult<UpdateAapLastSyncDateMutation>;
export type UpdateAapLastSyncDateMutationOptions = Apollo.BaseMutationOptions<UpdateAapLastSyncDateMutation, UpdateAapLastSyncDateMutationVariables>;
export const UpdateAclSyncDocument = gql`
    mutation updateACLSync($input: UpdateACLSyncInput!) {
  updateACLSync(input: $input) {
    accessories {
      ...AccessoryFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}`;
export type UpdateAclSyncMutationFn = Apollo.MutationFunction<UpdateAclSyncMutation, UpdateAclSyncMutationVariables>;

/**
 * __useUpdateAclSyncMutation__
 *
 * To run a mutation, you first call `useUpdateAclSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAclSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAclSyncMutation, { data, loading, error }] = useUpdateAclSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAclSyncMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAclSyncMutation, UpdateAclSyncMutationVariables>) {
        return Apollo.useMutation<UpdateAclSyncMutation, UpdateAclSyncMutationVariables>(UpdateAclSyncDocument, baseOptions);
      }
export type UpdateAclSyncMutationHookResult = ReturnType<typeof useUpdateAclSyncMutation>;
export type UpdateAclSyncMutationResult = Apollo.MutationResult<UpdateAclSyncMutation>;
export type UpdateAclSyncMutationOptions = Apollo.BaseMutationOptions<UpdateAclSyncMutation, UpdateAclSyncMutationVariables>;
export const UpdateAdsSyncDocument = gql`
    mutation updateADSSync($input: UpdateADSSyncInput!) {
  updateADSSync(input: $input) {
    accessories {
      ...AccessoryFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}`;
export type UpdateAdsSyncMutationFn = Apollo.MutationFunction<UpdateAdsSyncMutation, UpdateAdsSyncMutationVariables>;

/**
 * __useUpdateAdsSyncMutation__
 *
 * To run a mutation, you first call `useUpdateAdsSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdsSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdsSyncMutation, { data, loading, error }] = useUpdateAdsSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdsSyncMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdsSyncMutation, UpdateAdsSyncMutationVariables>) {
        return Apollo.useMutation<UpdateAdsSyncMutation, UpdateAdsSyncMutationVariables>(UpdateAdsSyncDocument, baseOptions);
      }
export type UpdateAdsSyncMutationHookResult = ReturnType<typeof useUpdateAdsSyncMutation>;
export type UpdateAdsSyncMutationResult = Apollo.MutationResult<UpdateAdsSyncMutation>;
export type UpdateAdsSyncMutationOptions = Apollo.BaseMutationOptions<UpdateAdsSyncMutation, UpdateAdsSyncMutationVariables>;
export const UpdateAccessoryDocument = gql`
    mutation updateAccessory($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $accessory: AccessoryItemUpdate!, $acceptChanges: Boolean) {
  updateAcc(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version, accessory: $accessory, acceptChanges: $acceptChanges}
  ) {
    accessory {
      ...AccessoryFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}`;
export type UpdateAccessoryMutationFn = Apollo.MutationFunction<UpdateAccessoryMutation, UpdateAccessoryMutationVariables>;

/**
 * __useUpdateAccessoryMutation__
 *
 * To run a mutation, you first call `useUpdateAccessoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessoryMutation, { data, loading, error }] = useUpdateAccessoryMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      accessory: // value for 'accessory'
 *      acceptChanges: // value for 'acceptChanges'
 *   },
 * });
 */
export function useUpdateAccessoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccessoryMutation, UpdateAccessoryMutationVariables>) {
        return Apollo.useMutation<UpdateAccessoryMutation, UpdateAccessoryMutationVariables>(UpdateAccessoryDocument, baseOptions);
      }
export type UpdateAccessoryMutationHookResult = ReturnType<typeof useUpdateAccessoryMutation>;
export type UpdateAccessoryMutationResult = Apollo.MutationResult<UpdateAccessoryMutation>;
export type UpdateAccessoryMutationOptions = Apollo.BaseMutationOptions<UpdateAccessoryMutation, UpdateAccessoryMutationVariables>;
export const UpdateProductTypeDocument = gql`
    mutation updateProductType($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!, $name: String!, $id: String!, $revId: String!) {
  updateRefItem(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version, referenceItemType: "productTypeRefItems", refItem: {id: $id, revId: $revId, name: $name}}
  ) {
    refItem {
      ...ProductTypeFields
    }
  }
}
    ${ProductTypeFieldsFragmentDoc}`;
export type UpdateProductTypeMutationFn = Apollo.MutationFunction<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>;

/**
 * __useUpdateProductTypeMutation__
 *
 * To run a mutation, you first call `useUpdateProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTypeMutation, { data, loading, error }] = useUpdateProductTypeMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *   },
 * });
 */
export function useUpdateProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>) {
        return Apollo.useMutation<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>(UpdateProductTypeDocument, baseOptions);
      }
export type UpdateProductTypeMutationHookResult = ReturnType<typeof useUpdateProductTypeMutation>;
export type UpdateProductTypeMutationResult = Apollo.MutationResult<UpdateProductTypeMutation>;
export type UpdateProductTypeMutationOptions = Apollo.BaseMutationOptions<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>;
export const GetAccessoryChangeLogDocument = gql`
    query getAccessoryChangeLog($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!) {
  changes(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
  ) {
    changes {
      ...AccessoryChangeLogFields
    }
  }
  latestVersion(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
  ) {
    latestVersion
    hasDraft
  }
  accessories(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
  ) {
    header {
      ...HeaderFields
    }
  }
}
    ${AccessoryChangeLogFieldsFragmentDoc}
${HeaderFieldsFragmentDoc}`;

/**
 * __useGetAccessoryChangeLogQuery__
 *
 * To run a query within a React component, call `useGetAccessoryChangeLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoryChangeLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoryChangeLogQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetAccessoryChangeLogQuery(baseOptions: Apollo.QueryHookOptions<GetAccessoryChangeLogQuery, GetAccessoryChangeLogQueryVariables>) {
        return Apollo.useQuery<GetAccessoryChangeLogQuery, GetAccessoryChangeLogQueryVariables>(GetAccessoryChangeLogDocument, baseOptions);
      }
export function useGetAccessoryChangeLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoryChangeLogQuery, GetAccessoryChangeLogQueryVariables>) {
          return Apollo.useLazyQuery<GetAccessoryChangeLogQuery, GetAccessoryChangeLogQueryVariables>(GetAccessoryChangeLogDocument, baseOptions);
        }
export type GetAccessoryChangeLogQueryHookResult = ReturnType<typeof useGetAccessoryChangeLogQuery>;
export type GetAccessoryChangeLogLazyQueryHookResult = ReturnType<typeof useGetAccessoryChangeLogLazyQuery>;
export type GetAccessoryChangeLogQueryResult = Apollo.QueryResult<GetAccessoryChangeLogQuery, GetAccessoryChangeLogQueryVariables>;
export const GetAccessoryChangeLogWithProductTypeDocument = gql`
    query getAccessoryChangeLogWithProductType($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!) {
  changes(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
  ) {
    changes {
      ...AccessoryChangeLogFields
    }
  }
  latestVersion(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
  ) {
    latestVersion
    hasDraft
  }
  accessories(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
  ) {
    header {
      ...HeaderFields
    }
  }
  refItems(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
    referenceItemType: "productTypeRefItems"
  ) {
    header {
      ...HeaderFields
    }
    refItems {
      ...ProductTypeFields
    }
  }
}
    ${AccessoryChangeLogFieldsFragmentDoc}
${HeaderFieldsFragmentDoc}
${ProductTypeFieldsFragmentDoc}`;

/**
 * __useGetAccessoryChangeLogWithProductTypeQuery__
 *
 * To run a query within a React component, call `useGetAccessoryChangeLogWithProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoryChangeLogWithProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoryChangeLogWithProductTypeQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetAccessoryChangeLogWithProductTypeQuery(baseOptions: Apollo.QueryHookOptions<GetAccessoryChangeLogWithProductTypeQuery, GetAccessoryChangeLogWithProductTypeQueryVariables>) {
        return Apollo.useQuery<GetAccessoryChangeLogWithProductTypeQuery, GetAccessoryChangeLogWithProductTypeQueryVariables>(GetAccessoryChangeLogWithProductTypeDocument, baseOptions);
      }
export function useGetAccessoryChangeLogWithProductTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoryChangeLogWithProductTypeQuery, GetAccessoryChangeLogWithProductTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetAccessoryChangeLogWithProductTypeQuery, GetAccessoryChangeLogWithProductTypeQueryVariables>(GetAccessoryChangeLogWithProductTypeDocument, baseOptions);
        }
export type GetAccessoryChangeLogWithProductTypeQueryHookResult = ReturnType<typeof useGetAccessoryChangeLogWithProductTypeQuery>;
export type GetAccessoryChangeLogWithProductTypeLazyQueryHookResult = ReturnType<typeof useGetAccessoryChangeLogWithProductTypeLazyQuery>;
export type GetAccessoryChangeLogWithProductTypeQueryResult = Apollo.QueryResult<GetAccessoryChangeLogWithProductTypeQuery, GetAccessoryChangeLogWithProductTypeQueryVariables>;
export const RevertAccessoryChangeLogDocument = gql`
    mutation revertAccessoryChangeLog($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $changeType: String!, $id: String!, $from: JSON, $to: JSON) {
  revertAccChange(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, revertChange: {changeType: $changeType, id: $id, from: $from, to: $to}}
  ) {
    message
    accessory {
      ...AccessoryFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}`;
export type RevertAccessoryChangeLogMutationFn = Apollo.MutationFunction<RevertAccessoryChangeLogMutation, RevertAccessoryChangeLogMutationVariables>;

/**
 * __useRevertAccessoryChangeLogMutation__
 *
 * To run a mutation, you first call `useRevertAccessoryChangeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertAccessoryChangeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAccessoryChangeLogMutation, { data, loading, error }] = useRevertAccessoryChangeLogMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      changeType: // value for 'changeType'
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRevertAccessoryChangeLogMutation(baseOptions?: Apollo.MutationHookOptions<RevertAccessoryChangeLogMutation, RevertAccessoryChangeLogMutationVariables>) {
        return Apollo.useMutation<RevertAccessoryChangeLogMutation, RevertAccessoryChangeLogMutationVariables>(RevertAccessoryChangeLogDocument, baseOptions);
      }
export type RevertAccessoryChangeLogMutationHookResult = ReturnType<typeof useRevertAccessoryChangeLogMutation>;
export type RevertAccessoryChangeLogMutationResult = Apollo.MutationResult<RevertAccessoryChangeLogMutation>;
export type RevertAccessoryChangeLogMutationOptions = Apollo.BaseMutationOptions<RevertAccessoryChangeLogMutation, RevertAccessoryChangeLogMutationVariables>;
export const AclArchivedDocumentsDocument = gql`
    query aclArchivedDocuments($input: ACLArchivedDocumentsInput!) {
  aclArchivedDocuments(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;

/**
 * __useAclArchivedDocumentsQuery__
 *
 * To run a query within a React component, call `useAclArchivedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclArchivedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclArchivedDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAclArchivedDocumentsQuery(baseOptions: Apollo.QueryHookOptions<AclArchivedDocumentsQuery, AclArchivedDocumentsQueryVariables>) {
        return Apollo.useQuery<AclArchivedDocumentsQuery, AclArchivedDocumentsQueryVariables>(AclArchivedDocumentsDocument, baseOptions);
      }
export function useAclArchivedDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AclArchivedDocumentsQuery, AclArchivedDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<AclArchivedDocumentsQuery, AclArchivedDocumentsQueryVariables>(AclArchivedDocumentsDocument, baseOptions);
        }
export type AclArchivedDocumentsQueryHookResult = ReturnType<typeof useAclArchivedDocumentsQuery>;
export type AclArchivedDocumentsLazyQueryHookResult = ReturnType<typeof useAclArchivedDocumentsLazyQuery>;
export type AclArchivedDocumentsQueryResult = Apollo.QueryResult<AclArchivedDocumentsQuery, AclArchivedDocumentsQueryVariables>;
export const AclChangesDocument = gql`
    query aclChanges($brand: String!, $region: String!, $team: String!, $version: String!) {
  aclChanges(brand: $brand, region: $region, team: $team, version: $version) {
    changes {
      changedItemId
      changeType
      before
      after
      modifiedBy
      modifiedDate
      changedItem {
        ...ACLItemFields
      }
    }
  }
  getACLProductTypes(input: {brand: $brand, region: $region, team: $team}) {
    id
    name
  }
  aclHeader(input: {brand: $brand, region: $region, team: $team})
}
    ${AclItemFieldsFragmentDoc}`;

/**
 * __useAclChangesQuery__
 *
 * To run a query within a React component, call `useAclChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclChangesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      region: // value for 'region'
 *      team: // value for 'team'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useAclChangesQuery(baseOptions: Apollo.QueryHookOptions<AclChangesQuery, AclChangesQueryVariables>) {
        return Apollo.useQuery<AclChangesQuery, AclChangesQueryVariables>(AclChangesDocument, baseOptions);
      }
export function useAclChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AclChangesQuery, AclChangesQueryVariables>) {
          return Apollo.useLazyQuery<AclChangesQuery, AclChangesQueryVariables>(AclChangesDocument, baseOptions);
        }
export type AclChangesQueryHookResult = ReturnType<typeof useAclChangesQuery>;
export type AclChangesLazyQueryHookResult = ReturnType<typeof useAclChangesLazyQuery>;
export type AclChangesQueryResult = Apollo.QueryResult<AclChangesQuery, AclChangesQueryVariables>;
export const AclCurrentVersionDocumentsDocument = gql`
    query aclCurrentVersionDocuments($brand: String!, $region: String!, $team: String!) {
  aclCurrentVersionDocuments(input: {brand: $brand, region: $region, team: $team}) {
    ...ACLFields
  }
  aclHeader(input: {brand: $brand, region: $region, team: $team})
}
    ${AclFieldsFragmentDoc}`;

/**
 * __useAclCurrentVersionDocumentsQuery__
 *
 * To run a query within a React component, call `useAclCurrentVersionDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclCurrentVersionDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclCurrentVersionDocumentsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      region: // value for 'region'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useAclCurrentVersionDocumentsQuery(baseOptions: Apollo.QueryHookOptions<AclCurrentVersionDocumentsQuery, AclCurrentVersionDocumentsQueryVariables>) {
        return Apollo.useQuery<AclCurrentVersionDocumentsQuery, AclCurrentVersionDocumentsQueryVariables>(AclCurrentVersionDocumentsDocument, baseOptions);
      }
export function useAclCurrentVersionDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AclCurrentVersionDocumentsQuery, AclCurrentVersionDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<AclCurrentVersionDocumentsQuery, AclCurrentVersionDocumentsQueryVariables>(AclCurrentVersionDocumentsDocument, baseOptions);
        }
export type AclCurrentVersionDocumentsQueryHookResult = ReturnType<typeof useAclCurrentVersionDocumentsQuery>;
export type AclCurrentVersionDocumentsLazyQueryHookResult = ReturnType<typeof useAclCurrentVersionDocumentsLazyQuery>;
export type AclCurrentVersionDocumentsQueryResult = Apollo.QueryResult<AclCurrentVersionDocumentsQuery, AclCurrentVersionDocumentsQueryVariables>;
export const AclDocumentsDocument = gql`
    query aclDocuments($input: ACLDocumentInput!) {
  aclDocuments(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;

/**
 * __useAclDocumentsQuery__
 *
 * To run a query within a React component, call `useAclDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAclDocumentsQuery(baseOptions: Apollo.QueryHookOptions<AclDocumentsQuery, AclDocumentsQueryVariables>) {
        return Apollo.useQuery<AclDocumentsQuery, AclDocumentsQueryVariables>(AclDocumentsDocument, baseOptions);
      }
export function useAclDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AclDocumentsQuery, AclDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<AclDocumentsQuery, AclDocumentsQueryVariables>(AclDocumentsDocument, baseOptions);
        }
export type AclDocumentsQueryHookResult = ReturnType<typeof useAclDocumentsQuery>;
export type AclDocumentsLazyQueryHookResult = ReturnType<typeof useAclDocumentsLazyQuery>;
export type AclDocumentsQueryResult = Apollo.QueryResult<AclDocumentsQuery, AclDocumentsQueryVariables>;
export const ArchiveAclDraftDocument = gql`
    mutation archiveACLDraft($input: ArchiveACLDraftInput!) {
  archiveACLDraft(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;
export type ArchiveAclDraftMutationFn = Apollo.MutationFunction<ArchiveAclDraftMutation, ArchiveAclDraftMutationVariables>;

/**
 * __useArchiveAclDraftMutation__
 *
 * To run a mutation, you first call `useArchiveAclDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAclDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAclDraftMutation, { data, loading, error }] = useArchiveAclDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveAclDraftMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveAclDraftMutation, ArchiveAclDraftMutationVariables>) {
        return Apollo.useMutation<ArchiveAclDraftMutation, ArchiveAclDraftMutationVariables>(ArchiveAclDraftDocument, baseOptions);
      }
export type ArchiveAclDraftMutationHookResult = ReturnType<typeof useArchiveAclDraftMutation>;
export type ArchiveAclDraftMutationResult = Apollo.MutationResult<ArchiveAclDraftMutation>;
export type ArchiveAclDraftMutationOptions = Apollo.BaseMutationOptions<ArchiveAclDraftMutation, ArchiveAclDraftMutationVariables>;
export const CreateAclDraftDocument = gql`
    mutation createACLDraft($input: CreateACLDraftInput!) {
  createACLDraft(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;
export type CreateAclDraftMutationFn = Apollo.MutationFunction<CreateAclDraftMutation, CreateAclDraftMutationVariables>;

/**
 * __useCreateAclDraftMutation__
 *
 * To run a mutation, you first call `useCreateAclDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAclDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAclDraftMutation, { data, loading, error }] = useCreateAclDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAclDraftMutation(baseOptions?: Apollo.MutationHookOptions<CreateAclDraftMutation, CreateAclDraftMutationVariables>) {
        return Apollo.useMutation<CreateAclDraftMutation, CreateAclDraftMutationVariables>(CreateAclDraftDocument, baseOptions);
      }
export type CreateAclDraftMutationHookResult = ReturnType<typeof useCreateAclDraftMutation>;
export type CreateAclDraftMutationResult = Apollo.MutationResult<CreateAclDraftMutation>;
export type CreateAclDraftMutationOptions = Apollo.BaseMutationOptions<CreateAclDraftMutation, CreateAclDraftMutationVariables>;
export const CreateAclProductTypeDocument = gql`
    mutation createACLProductType($input: CreateACLProductTypeInput!) {
  createACLProductType(input: $input) {
    id
    name
  }
}
    `;
export type CreateAclProductTypeMutationFn = Apollo.MutationFunction<CreateAclProductTypeMutation, CreateAclProductTypeMutationVariables>;

/**
 * __useCreateAclProductTypeMutation__
 *
 * To run a mutation, you first call `useCreateAclProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAclProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAclProductTypeMutation, { data, loading, error }] = useCreateAclProductTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAclProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateAclProductTypeMutation, CreateAclProductTypeMutationVariables>) {
        return Apollo.useMutation<CreateAclProductTypeMutation, CreateAclProductTypeMutationVariables>(CreateAclProductTypeDocument, baseOptions);
      }
export type CreateAclProductTypeMutationHookResult = ReturnType<typeof useCreateAclProductTypeMutation>;
export type CreateAclProductTypeMutationResult = Apollo.MutationResult<CreateAclProductTypeMutation>;
export type CreateAclProductTypeMutationOptions = Apollo.BaseMutationOptions<CreateAclProductTypeMutation, CreateAclProductTypeMutationVariables>;
export const CreateAccFromAclDocument = gql`
    mutation createAccFromACL($input: CreateAccFromACLInput!) {
  createAccFromACL(input: $input) {
    accessories {
      ...AccessoryFields
    }
    productTypes {
      ...ProductTypeFields
    }
  }
}
    ${AccessoryFieldsFragmentDoc}
${ProductTypeFieldsFragmentDoc}`;
export type CreateAccFromAclMutationFn = Apollo.MutationFunction<CreateAccFromAclMutation, CreateAccFromAclMutationVariables>;

/**
 * __useCreateAccFromAclMutation__
 *
 * To run a mutation, you first call `useCreateAccFromAclMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccFromAclMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccFromAclMutation, { data, loading, error }] = useCreateAccFromAclMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccFromAclMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccFromAclMutation, CreateAccFromAclMutationVariables>) {
        return Apollo.useMutation<CreateAccFromAclMutation, CreateAccFromAclMutationVariables>(CreateAccFromAclDocument, baseOptions);
      }
export type CreateAccFromAclMutationHookResult = ReturnType<typeof useCreateAccFromAclMutation>;
export type CreateAccFromAclMutationResult = Apollo.MutationResult<CreateAccFromAclMutation>;
export type CreateAccFromAclMutationOptions = Apollo.BaseMutationOptions<CreateAccFromAclMutation, CreateAccFromAclMutationVariables>;
export const DeleteAclDraftDocument = gql`
    mutation deleteACLDraft($input: DeleteACLDraftInput!) {
  deleteACLDraft(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;
export type DeleteAclDraftMutationFn = Apollo.MutationFunction<DeleteAclDraftMutation, DeleteAclDraftMutationVariables>;

/**
 * __useDeleteAclDraftMutation__
 *
 * To run a mutation, you first call `useDeleteAclDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAclDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAclDraftMutation, { data, loading, error }] = useDeleteAclDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAclDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAclDraftMutation, DeleteAclDraftMutationVariables>) {
        return Apollo.useMutation<DeleteAclDraftMutation, DeleteAclDraftMutationVariables>(DeleteAclDraftDocument, baseOptions);
      }
export type DeleteAclDraftMutationHookResult = ReturnType<typeof useDeleteAclDraftMutation>;
export type DeleteAclDraftMutationResult = Apollo.MutationResult<DeleteAclDraftMutation>;
export type DeleteAclDraftMutationOptions = Apollo.BaseMutationOptions<DeleteAclDraftMutation, DeleteAclDraftMutationVariables>;
export const GetAclProductTypesDocument = gql`
    query getACLProductTypes($input: GetACLProductTypesInput!) {
  getACLProductTypes(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useGetAclProductTypesQuery__
 *
 * To run a query within a React component, call `useGetAclProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAclProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAclProductTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAclProductTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAclProductTypesQuery, GetAclProductTypesQueryVariables>) {
        return Apollo.useQuery<GetAclProductTypesQuery, GetAclProductTypesQueryVariables>(GetAclProductTypesDocument, baseOptions);
      }
export function useGetAclProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAclProductTypesQuery, GetAclProductTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetAclProductTypesQuery, GetAclProductTypesQueryVariables>(GetAclProductTypesDocument, baseOptions);
        }
export type GetAclProductTypesQueryHookResult = ReturnType<typeof useGetAclProductTypesQuery>;
export type GetAclProductTypesLazyQueryHookResult = ReturnType<typeof useGetAclProductTypesLazyQuery>;
export type GetAclProductTypesQueryResult = Apollo.QueryResult<GetAclProductTypesQuery, GetAclProductTypesQueryVariables>;
export const GetAdsAccessoryCommonLanguageDocument = gql`
    query getAdsAccessoryCommonLanguage($adsPartNumber: String!) {
  adsAccessoryCommonLanguage(adsPartNumber: $adsPartNumber) {
    ...ACLItemFields
  }
}
    ${AclItemFieldsFragmentDoc}`;

/**
 * __useGetAdsAccessoryCommonLanguageQuery__
 *
 * To run a query within a React component, call `useGetAdsAccessoryCommonLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsAccessoryCommonLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsAccessoryCommonLanguageQuery({
 *   variables: {
 *      adsPartNumber: // value for 'adsPartNumber'
 *   },
 * });
 */
export function useGetAdsAccessoryCommonLanguageQuery(baseOptions: Apollo.QueryHookOptions<GetAdsAccessoryCommonLanguageQuery, GetAdsAccessoryCommonLanguageQueryVariables>) {
        return Apollo.useQuery<GetAdsAccessoryCommonLanguageQuery, GetAdsAccessoryCommonLanguageQueryVariables>(GetAdsAccessoryCommonLanguageDocument, baseOptions);
      }
export function useGetAdsAccessoryCommonLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdsAccessoryCommonLanguageQuery, GetAdsAccessoryCommonLanguageQueryVariables>) {
          return Apollo.useLazyQuery<GetAdsAccessoryCommonLanguageQuery, GetAdsAccessoryCommonLanguageQueryVariables>(GetAdsAccessoryCommonLanguageDocument, baseOptions);
        }
export type GetAdsAccessoryCommonLanguageQueryHookResult = ReturnType<typeof useGetAdsAccessoryCommonLanguageQuery>;
export type GetAdsAccessoryCommonLanguageLazyQueryHookResult = ReturnType<typeof useGetAdsAccessoryCommonLanguageLazyQuery>;
export type GetAdsAccessoryCommonLanguageQueryResult = Apollo.QueryResult<GetAdsAccessoryCommonLanguageQuery, GetAdsAccessoryCommonLanguageQueryVariables>;
export const GetCommonLanguageIdsDocument = gql`
    query getCommonLanguageIds($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!) {
  getComLangIds(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
  ) {
    comLangIds {
      id
      publishedVersion
      isArchived
    }
  }
}
    `;

/**
 * __useGetCommonLanguageIdsQuery__
 *
 * To run a query within a React component, call `useGetCommonLanguageIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonLanguageIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonLanguageIdsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetCommonLanguageIdsQuery(baseOptions: Apollo.QueryHookOptions<GetCommonLanguageIdsQuery, GetCommonLanguageIdsQueryVariables>) {
        return Apollo.useQuery<GetCommonLanguageIdsQuery, GetCommonLanguageIdsQueryVariables>(GetCommonLanguageIdsDocument, baseOptions);
      }
export function useGetCommonLanguageIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommonLanguageIdsQuery, GetCommonLanguageIdsQueryVariables>) {
          return Apollo.useLazyQuery<GetCommonLanguageIdsQuery, GetCommonLanguageIdsQueryVariables>(GetCommonLanguageIdsDocument, baseOptions);
        }
export type GetCommonLanguageIdsQueryHookResult = ReturnType<typeof useGetCommonLanguageIdsQuery>;
export type GetCommonLanguageIdsLazyQueryHookResult = ReturnType<typeof useGetCommonLanguageIdsLazyQuery>;
export type GetCommonLanguageIdsQueryResult = Apollo.QueryResult<GetCommonLanguageIdsQuery, GetCommonLanguageIdsQueryVariables>;
export const PublishAclDraftDocument = gql`
    mutation publishACLDraft($input: PublishACLDraftInput!) {
  publishACLDraft(input: $input) {
    version
    publishedBy
    publishedDate
    lastResetDate
    changes {
      changeType
      before
      after
    }
  }
}
    `;
export type PublishAclDraftMutationFn = Apollo.MutationFunction<PublishAclDraftMutation, PublishAclDraftMutationVariables>;

/**
 * __usePublishAclDraftMutation__
 *
 * To run a mutation, you first call `usePublishAclDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAclDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAclDraftMutation, { data, loading, error }] = usePublishAclDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishAclDraftMutation(baseOptions?: Apollo.MutationHookOptions<PublishAclDraftMutation, PublishAclDraftMutationVariables>) {
        return Apollo.useMutation<PublishAclDraftMutation, PublishAclDraftMutationVariables>(PublishAclDraftDocument, baseOptions);
      }
export type PublishAclDraftMutationHookResult = ReturnType<typeof usePublishAclDraftMutation>;
export type PublishAclDraftMutationResult = Apollo.MutationResult<PublishAclDraftMutation>;
export type PublishAclDraftMutationOptions = Apollo.BaseMutationOptions<PublishAclDraftMutation, PublishAclDraftMutationVariables>;
export const ResetAclDraftDocument = gql`
    mutation resetACLDraft($input: ResetACLDraftInput!) {
  resetACLDraft(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;
export type ResetAclDraftMutationFn = Apollo.MutationFunction<ResetAclDraftMutation, ResetAclDraftMutationVariables>;

/**
 * __useResetAclDraftMutation__
 *
 * To run a mutation, you first call `useResetAclDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAclDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAclDraftMutation, { data, loading, error }] = useResetAclDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetAclDraftMutation(baseOptions?: Apollo.MutationHookOptions<ResetAclDraftMutation, ResetAclDraftMutationVariables>) {
        return Apollo.useMutation<ResetAclDraftMutation, ResetAclDraftMutationVariables>(ResetAclDraftDocument, baseOptions);
      }
export type ResetAclDraftMutationHookResult = ReturnType<typeof useResetAclDraftMutation>;
export type ResetAclDraftMutationResult = Apollo.MutationResult<ResetAclDraftMutation>;
export type ResetAclDraftMutationOptions = Apollo.BaseMutationOptions<ResetAclDraftMutation, ResetAclDraftMutationVariables>;
export const RevertAclChangeDocument = gql`
    mutation revertACLChange($input: RevertACLChangeInput!) {
  revertACLChange(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;
export type RevertAclChangeMutationFn = Apollo.MutationFunction<RevertAclChangeMutation, RevertAclChangeMutationVariables>;

/**
 * __useRevertAclChangeMutation__
 *
 * To run a mutation, you first call `useRevertAclChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertAclChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAclChangeMutation, { data, loading, error }] = useRevertAclChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertAclChangeMutation(baseOptions?: Apollo.MutationHookOptions<RevertAclChangeMutation, RevertAclChangeMutationVariables>) {
        return Apollo.useMutation<RevertAclChangeMutation, RevertAclChangeMutationVariables>(RevertAclChangeDocument, baseOptions);
      }
export type RevertAclChangeMutationHookResult = ReturnType<typeof useRevertAclChangeMutation>;
export type RevertAclChangeMutationResult = Apollo.MutationResult<RevertAclChangeMutation>;
export type RevertAclChangeMutationOptions = Apollo.BaseMutationOptions<RevertAclChangeMutation, RevertAclChangeMutationVariables>;
export const UpdateAclDraftDocument = gql`
    mutation updateACLDraft($input: UpdateACLDraftInput!) {
  updateACLDraft(input: $input) {
    ...ACLFields
  }
}
    ${AclFieldsFragmentDoc}`;
export type UpdateAclDraftMutationFn = Apollo.MutationFunction<UpdateAclDraftMutation, UpdateAclDraftMutationVariables>;

/**
 * __useUpdateAclDraftMutation__
 *
 * To run a mutation, you first call `useUpdateAclDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAclDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAclDraftMutation, { data, loading, error }] = useUpdateAclDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAclDraftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAclDraftMutation, UpdateAclDraftMutationVariables>) {
        return Apollo.useMutation<UpdateAclDraftMutation, UpdateAclDraftMutationVariables>(UpdateAclDraftDocument, baseOptions);
      }
export type UpdateAclDraftMutationHookResult = ReturnType<typeof useUpdateAclDraftMutation>;
export type UpdateAclDraftMutationResult = Apollo.MutationResult<UpdateAclDraftMutation>;
export type UpdateAclDraftMutationOptions = Apollo.BaseMutationOptions<UpdateAclDraftMutation, UpdateAclDraftMutationVariables>;
export const UpdateAclProductTypeDocument = gql`
    mutation updateACLProductType($input: UpdateACLProductTypeInput!) {
  updateACLProductType(input: $input) {
    id
    name
  }
}
    `;
export type UpdateAclProductTypeMutationFn = Apollo.MutationFunction<UpdateAclProductTypeMutation, UpdateAclProductTypeMutationVariables>;

/**
 * __useUpdateAclProductTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAclProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAclProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAclProductTypeMutation, { data, loading, error }] = useUpdateAclProductTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAclProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAclProductTypeMutation, UpdateAclProductTypeMutationVariables>) {
        return Apollo.useMutation<UpdateAclProductTypeMutation, UpdateAclProductTypeMutationVariables>(UpdateAclProductTypeDocument, baseOptions);
      }
export type UpdateAclProductTypeMutationHookResult = ReturnType<typeof useUpdateAclProductTypeMutation>;
export type UpdateAclProductTypeMutationResult = Apollo.MutationResult<UpdateAclProductTypeMutation>;
export type UpdateAclProductTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAclProductTypeMutation, UpdateAclProductTypeMutationVariables>;
export const UpdateGoLiveDateDocument = gql`
    mutation UpdateGoLiveDate($brand: String!, $goLiveDates: [GoLiveDateUpdate!]!, $lang: String!, $modelYear: Int!, $region: String!, $seriesId: String!, $team: String!) {
  updateGoLiveDate(
    input: {brand: $brand, goLiveDates: $goLiveDates, lang: $lang, modelYear: $modelYear, region: $region, seriesId: $seriesId, team: $team}
  ) {
    goLiveDate
    id
    revId
  }
}
    `;
export type UpdateGoLiveDateMutationFn = Apollo.MutationFunction<UpdateGoLiveDateMutation, UpdateGoLiveDateMutationVariables>;

/**
 * __useUpdateGoLiveDateMutation__
 *
 * To run a mutation, you first call `useUpdateGoLiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoLiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoLiveDateMutation, { data, loading, error }] = useUpdateGoLiveDateMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      goLiveDates: // value for 'goLiveDates'
 *      lang: // value for 'lang'
 *      modelYear: // value for 'modelYear'
 *      region: // value for 'region'
 *      seriesId: // value for 'seriesId'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useUpdateGoLiveDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoLiveDateMutation, UpdateGoLiveDateMutationVariables>) {
        return Apollo.useMutation<UpdateGoLiveDateMutation, UpdateGoLiveDateMutationVariables>(UpdateGoLiveDateDocument, baseOptions);
      }
export type UpdateGoLiveDateMutationHookResult = ReturnType<typeof useUpdateGoLiveDateMutation>;
export type UpdateGoLiveDateMutationResult = Apollo.MutationResult<UpdateGoLiveDateMutation>;
export type UpdateGoLiveDateMutationOptions = Apollo.BaseMutationOptions<UpdateGoLiveDateMutation, UpdateGoLiveDateMutationVariables>;
export const GetDashboardDocument = gql`
    query getDashboard($brand: String!, $team: String!, $region: String!, $lang: String!) {
  dashboard(brand: $brand, team: $team, region: $region, lang: $lang) {
    series {
      ...DashboardFields
    }
  }
}
    ${DashboardFieldsFragmentDoc}`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const DownloadAccessoriesDocument = gql`
    query downloadAccessories($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: Int!) {
  downloadAccDocument(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
    version: $version
  ) {
    header {
      ...DownloadHeaderFields
    }
    accessories {
      ...AccessoryFields
    }
    refItems {
      ...ProductTypeFields
    }
  }
}
    ${DownloadHeaderFieldsFragmentDoc}
${AccessoryFieldsFragmentDoc}
${ProductTypeFieldsFragmentDoc}`;

/**
 * __useDownloadAccessoriesQuery__
 *
 * To run a query within a React component, call `useDownloadAccessoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAccessoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAccessoriesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDownloadAccessoriesQuery(baseOptions: Apollo.QueryHookOptions<DownloadAccessoriesQuery, DownloadAccessoriesQueryVariables>) {
        return Apollo.useQuery<DownloadAccessoriesQuery, DownloadAccessoriesQueryVariables>(DownloadAccessoriesDocument, baseOptions);
      }
export function useDownloadAccessoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadAccessoriesQuery, DownloadAccessoriesQueryVariables>) {
          return Apollo.useLazyQuery<DownloadAccessoriesQuery, DownloadAccessoriesQueryVariables>(DownloadAccessoriesDocument, baseOptions);
        }
export type DownloadAccessoriesQueryHookResult = ReturnType<typeof useDownloadAccessoriesQuery>;
export type DownloadAccessoriesLazyQueryHookResult = ReturnType<typeof useDownloadAccessoriesLazyQuery>;
export type DownloadAccessoriesQueryResult = Apollo.QueryResult<DownloadAccessoriesQuery, DownloadAccessoriesQueryVariables>;
export const CopyAccessoryDocumentDocument = gql`
    mutation copyAccessoryDocument($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYearFrom: Int!, $modelYearTo: Int!) {
  copyAccDoc(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYearFrom: $modelYearFrom, modelYearTo: $modelYearTo}
  ) {
    draft {
      ...DraftFields
    }
  }
}
    ${DraftFieldsFragmentDoc}`;
export type CopyAccessoryDocumentMutationFn = Apollo.MutationFunction<CopyAccessoryDocumentMutation, CopyAccessoryDocumentMutationVariables>;

/**
 * __useCopyAccessoryDocumentMutation__
 *
 * To run a mutation, you first call `useCopyAccessoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyAccessoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyAccessoryDocumentMutation, { data, loading, error }] = useCopyAccessoryDocumentMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYearFrom: // value for 'modelYearFrom'
 *      modelYearTo: // value for 'modelYearTo'
 *   },
 * });
 */
export function useCopyAccessoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CopyAccessoryDocumentMutation, CopyAccessoryDocumentMutationVariables>) {
        return Apollo.useMutation<CopyAccessoryDocumentMutation, CopyAccessoryDocumentMutationVariables>(CopyAccessoryDocumentDocument, baseOptions);
      }
export type CopyAccessoryDocumentMutationHookResult = ReturnType<typeof useCopyAccessoryDocumentMutation>;
export type CopyAccessoryDocumentMutationResult = Apollo.MutationResult<CopyAccessoryDocumentMutation>;
export type CopyAccessoryDocumentMutationOptions = Apollo.BaseMutationOptions<CopyAccessoryDocumentMutation, CopyAccessoryDocumentMutationVariables>;
export const CreateAccessoryDraftDocument = gql`
    mutation createAccessoryDraft($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!) {
  createAccDraft(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear}
  ) {
    draft {
      ...DraftFields
    }
  }
}
    ${DraftFieldsFragmentDoc}`;
export type CreateAccessoryDraftMutationFn = Apollo.MutationFunction<CreateAccessoryDraftMutation, CreateAccessoryDraftMutationVariables>;

/**
 * __useCreateAccessoryDraftMutation__
 *
 * To run a mutation, you first call `useCreateAccessoryDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessoryDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessoryDraftMutation, { data, loading, error }] = useCreateAccessoryDraftMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useCreateAccessoryDraftMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessoryDraftMutation, CreateAccessoryDraftMutationVariables>) {
        return Apollo.useMutation<CreateAccessoryDraftMutation, CreateAccessoryDraftMutationVariables>(CreateAccessoryDraftDocument, baseOptions);
      }
export type CreateAccessoryDraftMutationHookResult = ReturnType<typeof useCreateAccessoryDraftMutation>;
export type CreateAccessoryDraftMutationResult = Apollo.MutationResult<CreateAccessoryDraftMutation>;
export type CreateAccessoryDraftMutationOptions = Apollo.BaseMutationOptions<CreateAccessoryDraftMutation, CreateAccessoryDraftMutationVariables>;
export const DeleteAccessoryDraftDocument = gql`
    mutation deleteAccessoryDraft($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!) {
  deleteAccDraft(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear}
  ) {
    message
  }
}
    `;
export type DeleteAccessoryDraftMutationFn = Apollo.MutationFunction<DeleteAccessoryDraftMutation, DeleteAccessoryDraftMutationVariables>;

/**
 * __useDeleteAccessoryDraftMutation__
 *
 * To run a mutation, you first call `useDeleteAccessoryDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessoryDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessoryDraftMutation, { data, loading, error }] = useDeleteAccessoryDraftMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useDeleteAccessoryDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccessoryDraftMutation, DeleteAccessoryDraftMutationVariables>) {
        return Apollo.useMutation<DeleteAccessoryDraftMutation, DeleteAccessoryDraftMutationVariables>(DeleteAccessoryDraftDocument, baseOptions);
      }
export type DeleteAccessoryDraftMutationHookResult = ReturnType<typeof useDeleteAccessoryDraftMutation>;
export type DeleteAccessoryDraftMutationResult = Apollo.MutationResult<DeleteAccessoryDraftMutation>;
export type DeleteAccessoryDraftMutationOptions = Apollo.BaseMutationOptions<DeleteAccessoryDraftMutation, DeleteAccessoryDraftMutationVariables>;
export const PublishAccessoryDraftDocument = gql`
    mutation publishAccessoryDraft($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!) {
  publishAccDraft(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear}
  ) {
    publishedAccDoc {
      ...DraftFields
    }
  }
}
    ${DraftFieldsFragmentDoc}`;
export type PublishAccessoryDraftMutationFn = Apollo.MutationFunction<PublishAccessoryDraftMutation, PublishAccessoryDraftMutationVariables>;

/**
 * __usePublishAccessoryDraftMutation__
 *
 * To run a mutation, you first call `usePublishAccessoryDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAccessoryDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAccessoryDraftMutation, { data, loading, error }] = usePublishAccessoryDraftMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function usePublishAccessoryDraftMutation(baseOptions?: Apollo.MutationHookOptions<PublishAccessoryDraftMutation, PublishAccessoryDraftMutationVariables>) {
        return Apollo.useMutation<PublishAccessoryDraftMutation, PublishAccessoryDraftMutationVariables>(PublishAccessoryDraftDocument, baseOptions);
      }
export type PublishAccessoryDraftMutationHookResult = ReturnType<typeof usePublishAccessoryDraftMutation>;
export type PublishAccessoryDraftMutationResult = Apollo.MutationResult<PublishAccessoryDraftMutation>;
export type PublishAccessoryDraftMutationOptions = Apollo.BaseMutationOptions<PublishAccessoryDraftMutation, PublishAccessoryDraftMutationVariables>;
export const PublishDownstreamAccessoryDataDocument = gql`
    mutation publishDownstreamAccessoryData($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $publishType: String!) {
  publishDownstreamAccData(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, publishType: $publishType}
  ) {
    seriesItem {
      ...SeriesFields
    }
  }
}
    ${SeriesFieldsFragmentDoc}`;
export type PublishDownstreamAccessoryDataMutationFn = Apollo.MutationFunction<PublishDownstreamAccessoryDataMutation, PublishDownstreamAccessoryDataMutationVariables>;

/**
 * __usePublishDownstreamAccessoryDataMutation__
 *
 * To run a mutation, you first call `usePublishDownstreamAccessoryDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDownstreamAccessoryDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDownstreamAccessoryDataMutation, { data, loading, error }] = usePublishDownstreamAccessoryDataMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      publishType: // value for 'publishType'
 *   },
 * });
 */
export function usePublishDownstreamAccessoryDataMutation(baseOptions?: Apollo.MutationHookOptions<PublishDownstreamAccessoryDataMutation, PublishDownstreamAccessoryDataMutationVariables>) {
        return Apollo.useMutation<PublishDownstreamAccessoryDataMutation, PublishDownstreamAccessoryDataMutationVariables>(PublishDownstreamAccessoryDataDocument, baseOptions);
      }
export type PublishDownstreamAccessoryDataMutationHookResult = ReturnType<typeof usePublishDownstreamAccessoryDataMutation>;
export type PublishDownstreamAccessoryDataMutationResult = Apollo.MutationResult<PublishDownstreamAccessoryDataMutation>;
export type PublishDownstreamAccessoryDataMutationOptions = Apollo.BaseMutationOptions<PublishDownstreamAccessoryDataMutation, PublishDownstreamAccessoryDataMutationVariables>;
export const GenerateReportDocument = gql`
    mutation generateReport($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $version: String!) {
  generateAccReport(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, version: $version}
  ) {
    reportLogResponse {
      ...ReportLogFields
    }
  }
}
    ${ReportLogFieldsFragmentDoc}`;
export type GenerateReportMutationFn = Apollo.MutationFunction<GenerateReportMutation, GenerateReportMutationVariables>;

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGenerateReportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateReportMutation, GenerateReportMutationVariables>) {
        return Apollo.useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GenerateReportDocument, baseOptions);
      }
export type GenerateReportMutationHookResult = ReturnType<typeof useGenerateReportMutation>;
export type GenerateReportMutationResult = Apollo.MutationResult<GenerateReportMutation>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<GenerateReportMutation, GenerateReportMutationVariables>;
export const GetGeneratedReportsDocument = gql`
    query getGeneratedReports($brand: String!, $team: String!, $region: String!) {
  readReportLog(brand: $brand, team: $team, region: $region) {
    reports {
      ...ReportLogFields
    }
  }
}
    ${ReportLogFieldsFragmentDoc}`;

/**
 * __useGetGeneratedReportsQuery__
 *
 * To run a query within a React component, call `useGetGeneratedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedReportsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetGeneratedReportsQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedReportsQuery, GetGeneratedReportsQueryVariables>) {
        return Apollo.useQuery<GetGeneratedReportsQuery, GetGeneratedReportsQueryVariables>(GetGeneratedReportsDocument, baseOptions);
      }
export function useGetGeneratedReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedReportsQuery, GetGeneratedReportsQueryVariables>) {
          return Apollo.useLazyQuery<GetGeneratedReportsQuery, GetGeneratedReportsQueryVariables>(GetGeneratedReportsDocument, baseOptions);
        }
export type GetGeneratedReportsQueryHookResult = ReturnType<typeof useGetGeneratedReportsQuery>;
export type GetGeneratedReportsLazyQueryHookResult = ReturnType<typeof useGetGeneratedReportsLazyQuery>;
export type GetGeneratedReportsQueryResult = Apollo.QueryResult<GetGeneratedReportsQuery, GetGeneratedReportsQueryVariables>;
export const GetLatestVersionDocument = gql`
    query getLatestVersion($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!) {
  latestVersion(
    brand: $brand
    team: $team
    region: $region
    lang: $lang
    seriesId: $seriesId
    modelYear: $modelYear
  ) {
    latestVersion
    hasDraft
    publishedDate
  }
}
    `;

/**
 * __useGetLatestVersionQuery__
 *
 * To run a query within a React component, call `useGetLatestVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVersionQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useGetLatestVersionQuery(baseOptions: Apollo.QueryHookOptions<GetLatestVersionQuery, GetLatestVersionQueryVariables>) {
        return Apollo.useQuery<GetLatestVersionQuery, GetLatestVersionQueryVariables>(GetLatestVersionDocument, baseOptions);
      }
export function useGetLatestVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestVersionQuery, GetLatestVersionQueryVariables>) {
          return Apollo.useLazyQuery<GetLatestVersionQuery, GetLatestVersionQueryVariables>(GetLatestVersionDocument, baseOptions);
        }
export type GetLatestVersionQueryHookResult = ReturnType<typeof useGetLatestVersionQuery>;
export type GetLatestVersionLazyQueryHookResult = ReturnType<typeof useGetLatestVersionLazyQuery>;
export type GetLatestVersionQueryResult = Apollo.QueryResult<GetLatestVersionQuery, GetLatestVersionQueryVariables>;
export const UpdateProductTypeSortDocument = gql`
    mutation updateProductTypeSort($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $sortList: [SortItemInput!]!) {
  updateProductTypeSort(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, sortList: $sortList}
  ) {
    sortList {
      id
      sortOrder
    }
  }
}
    `;
export type UpdateProductTypeSortMutationFn = Apollo.MutationFunction<UpdateProductTypeSortMutation, UpdateProductTypeSortMutationVariables>;

/**
 * __useUpdateProductTypeSortMutation__
 *
 * To run a mutation, you first call `useUpdateProductTypeSortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTypeSortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTypeSortMutation, { data, loading, error }] = useUpdateProductTypeSortMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      sortList: // value for 'sortList'
 *   },
 * });
 */
export function useUpdateProductTypeSortMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductTypeSortMutation, UpdateProductTypeSortMutationVariables>) {
        return Apollo.useMutation<UpdateProductTypeSortMutation, UpdateProductTypeSortMutationVariables>(UpdateProductTypeSortDocument, baseOptions);
      }
export type UpdateProductTypeSortMutationHookResult = ReturnType<typeof useUpdateProductTypeSortMutation>;
export type UpdateProductTypeSortMutationResult = Apollo.MutationResult<UpdateProductTypeSortMutation>;
export type UpdateProductTypeSortMutationOptions = Apollo.BaseMutationOptions<UpdateProductTypeSortMutation, UpdateProductTypeSortMutationVariables>;
export const UpdateSortDocument = gql`
    mutation updateSort($brand: String!, $team: String!, $region: String!, $lang: String!, $seriesId: String!, $modelYear: Int!, $sortList: [SortItemInput!]!) {
  updateSort(
    input: {brand: $brand, team: $team, region: $region, lang: $lang, seriesId: $seriesId, modelYear: $modelYear, sortList: $sortList}
  ) {
    sortList {
      id
      sortOrder
    }
  }
}
    `;
export type UpdateSortMutationFn = Apollo.MutationFunction<UpdateSortMutation, UpdateSortMutationVariables>;

/**
 * __useUpdateSortMutation__
 *
 * To run a mutation, you first call `useUpdateSortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSortMutation, { data, loading, error }] = useUpdateSortMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      lang: // value for 'lang'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      sortList: // value for 'sortList'
 *   },
 * });
 */
export function useUpdateSortMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSortMutation, UpdateSortMutationVariables>) {
        return Apollo.useMutation<UpdateSortMutation, UpdateSortMutationVariables>(UpdateSortDocument, baseOptions);
      }
export type UpdateSortMutationHookResult = ReturnType<typeof useUpdateSortMutation>;
export type UpdateSortMutationResult = Apollo.MutationResult<UpdateSortMutation>;
export type UpdateSortMutationOptions = Apollo.BaseMutationOptions<UpdateSortMutation, UpdateSortMutationVariables>;
export const UpdateSpanishSyncDocument = gql`
    mutation updateSpanishSync($brand: String!, $team: String!, $region: String!, $seriesId: String!, $modelYear: Int!) {
  spanishSync(
    input: {header: {brand: $brand, team: $team, region: $region, seriesId: $seriesId, modelYear: $modelYear}}
  ) {
    document {
      ...HeaderFields
    }
  }
}
    ${HeaderFieldsFragmentDoc}`;
export type UpdateSpanishSyncMutationFn = Apollo.MutationFunction<UpdateSpanishSyncMutation, UpdateSpanishSyncMutationVariables>;

/**
 * __useUpdateSpanishSyncMutation__
 *
 * To run a mutation, you first call `useUpdateSpanishSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpanishSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpanishSyncMutation, { data, loading, error }] = useUpdateSpanishSyncMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      region: // value for 'region'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useUpdateSpanishSyncMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpanishSyncMutation, UpdateSpanishSyncMutationVariables>) {
        return Apollo.useMutation<UpdateSpanishSyncMutation, UpdateSpanishSyncMutationVariables>(UpdateSpanishSyncDocument, baseOptions);
      }
export type UpdateSpanishSyncMutationHookResult = ReturnType<typeof useUpdateSpanishSyncMutation>;
export type UpdateSpanishSyncMutationResult = Apollo.MutationResult<UpdateSpanishSyncMutation>;
export type UpdateSpanishSyncMutationOptions = Apollo.BaseMutationOptions<UpdateSpanishSyncMutation, UpdateSpanishSyncMutationVariables>;
export const UploadAccessoriesDocument = gql`
    mutation uploadAccessories($lang: String!, $header: AccDocumentInput!, $accessories: [AccessoryItemUpdate!]!, $refItems: [UploadRefItemInput!]!) {
  uploadAccDocument(
    input: {lang: $lang, header: $header, accessories: $accessories, refItems: $refItems}
  ) {
    document {
      ...AccDocFields
    }
  }
}
    ${AccDocFieldsFragmentDoc}`;
export type UploadAccessoriesMutationFn = Apollo.MutationFunction<UploadAccessoriesMutation, UploadAccessoriesMutationVariables>;

/**
 * __useUploadAccessoriesMutation__
 *
 * To run a mutation, you first call `useUploadAccessoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAccessoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAccessoriesMutation, { data, loading, error }] = useUploadAccessoriesMutation({
 *   variables: {
 *      lang: // value for 'lang'
 *      header: // value for 'header'
 *      accessories: // value for 'accessories'
 *      refItems: // value for 'refItems'
 *   },
 * });
 */
export function useUploadAccessoriesMutation(baseOptions?: Apollo.MutationHookOptions<UploadAccessoriesMutation, UploadAccessoriesMutationVariables>) {
        return Apollo.useMutation<UploadAccessoriesMutation, UploadAccessoriesMutationVariables>(UploadAccessoriesDocument, baseOptions);
      }
export type UploadAccessoriesMutationHookResult = ReturnType<typeof useUploadAccessoriesMutation>;
export type UploadAccessoriesMutationResult = Apollo.MutationResult<UploadAccessoriesMutation>;
export type UploadAccessoriesMutationOptions = Apollo.BaseMutationOptions<UploadAccessoriesMutation, UploadAccessoriesMutationVariables>;