import { observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { ImageItem, cleanUpRte } from "vapi-ui-common";
import { AccCommonLanguage, AclDocument } from "../gql/generated";
import omit from "../utils/omit";
import { AccessoryItemBase } from "./accessories/AccessoryItemVM";

function stringIsNotEmpty(value: string | null | undefined): boolean {
  return value !== null && value !== undefined && value !== "";
}

function cleanAclDocument(
  aclDocument: AclDocument
): Omit<
  AccessoryCommonLanguageItem,
  "uid" | "toAccCommonLanguage" | "isValid" | "updateFromACLDocument"
> {
  const images = aclDocument.accessory.images?.map((image) =>
    omit(image, ["__typename"])
  );

  const accessory: Omit<
    AccessoryCommonLanguageItem,
    "uid" | "toAccCommonLanguage" | "isValid" | "updateFromACLDocument"
  > = {
    id: aclDocument.sk,
    revId: aclDocument.revId,
    ...omit(aclDocument.accessory, ["__typename", "images"]),
    images,
    publishedDate: aclDocument.publishedDate,
    isArchived: aclDocument.isArchived,
    isDeleted: aclDocument.isDeleted,
    changedAttributes: Array.from(
      aclDocument.changes
        .reduce((acc, item) => {
          return acc.add(item.changeType);
        }, new Set<string>())
        .values()
    ),
  };

  return accessory;
}

export class AccessoryCommonLanguageItem implements AccessoryItemBase {
  @observable uid: string = "";
  @observable id: string = "";
  @observable revId: string = "";
  @observable sortOrder?: number | string = 0;

  @observable title: string = "";
  @observable title_es?: string | null = null;
  @observable copy?: string | null = null;
  @observable disclosure?: string | null = null;
  @observable productType: string = "";
  @observable installPoint?: string | null = null;
  @observable ppoCode?: string | null = null;
  @observable supplier?: string | null = null;
  @observable description?: string | null = null;
  @observable description_es?: string | null = null;
  @observable disclaimer?: string | null = null;
  @observable disclaimer_es?: string | null = null;
  @observable msrp?: string | null = null;
  @observable notes?: string | null = null;
  @observable images?: ImageItem[] | null = null;
  @observable partNumber?: string | null = null;
  @observable partsOnlyDealerCost?: string | null = null;
  @observable partsOnlyMSRP?: string | null = null;
  @observable installedDealerCost?: string | null = null;
  @observable required?: string | null = null;
  @observable conflicts?: string | null = null;
  @observable isNonGenAccessory?: boolean | null = false;
  @observable warranty?: string | null = null;
  @observable warranty_es?: string | null = null;

  @observable changedAttributes?: string[];
  @observable publishedDate?: string | null = null;
  @observable isArchived?: boolean | null = null;
  @observable isDeleted?: boolean | null = null;
  @observable laborTime?: string | null = null;
  @observable adsId?: string | null = null;
  @observable hasADSUpdates?: boolean | null = false;

  constructor(
    item?: Omit<
      AccessoryCommonLanguageItem,
      "uid" | "toAccCommonLanguage" | "isValid" | "updateFromACLDocument"
    >
  ) {
    this.uid = uuidv4();
    if (item) {
      const clone = { ...item, uid: this.uid };
      Object.assign(this, clone);
    }
  }

  static fromACLDocument = (aclDocument: AclDocument) => {
    return new AccessoryCommonLanguageItem(cleanAclDocument(aclDocument));
  };

  updateFromACLDocument = (aclDocument: AclDocument) => {
    Object.assign(this, cleanAclDocument(aclDocument));
  };

  toAccCommonLanguage = (): Omit<AccCommonLanguage, "__typename"> => {
    return {
      title: cleanUpRte(this.title),
      title_es: cleanUpRte(this.title_es),
      copy: cleanUpRte(this.copy),
      disclosure: cleanUpRte(this.disclosure),
      productType: this.productType,
      installPoint: this.installPoint,
      ppoCode: cleanUpRte(this.ppoCode),
      supplier: cleanUpRte(this.supplier),
      description: cleanUpRte(this.description),
      description_es: cleanUpRte(this.description_es),
      disclaimer: cleanUpRte(this.disclaimer),
      disclaimer_es: cleanUpRte(this.disclaimer_es),
      msrp: this.msrp,
      notes: this.notes,
      images: this.images,
      partNumber: cleanUpRte(this.partNumber),
      partsOnlyDealerCost: cleanUpRte(this.partsOnlyDealerCost),
      partsOnlyMSRP: cleanUpRte(this.partsOnlyMSRP),
      installedDealerCost: this.installedDealerCost,
      required: this.required ?? "",
      conflicts: this.conflicts ?? "",
      isNonGenAccessory: this.isNonGenAccessory,
      warranty: cleanUpRte(this.warranty),
      warranty_es: cleanUpRte(this.warranty_es),
      laborTime: this.laborTime,
    };
  };

  isValid = (gst: boolean = false) => {
    if (gst) {
      if (!stringIsNotEmpty(this.title)) {
        throw new Error("name is required");
      }
      if (!stringIsNotEmpty(this.title_es)) {
        throw new Error("name spanish is required");
      }
      if (!stringIsNotEmpty(this.productType)) {
        throw new Error("product type is required");
      }
    } else {
      if (!stringIsNotEmpty(this.title)) {
        throw new Error("name is required");
      }
      if (!stringIsNotEmpty(this.copy)) {
        throw new Error("copy is required");
      }
      if (!stringIsNotEmpty(this.productType)) {
        throw new Error("product type is required");
      }
      if (
        !stringIsNotEmpty(this.installPoint) ||
        (this.installPoint ?? "").split(",").length > 1
      ) {
        throw new Error("install point is invalid");
      }
      if (
        !stringIsNotEmpty(this.ppoCode) &&
        !this.installPoint?.includes("DIO") &&
        !this.isNonGenAccessory
      ) {
        throw new Error("PPO code is required");
      }
    }
  };
}

export interface AccessoryCommonLanguageResponse
  extends Omit<AccessoryItemBase, "uid"> {}

export interface AccessoryCommonLanguageRequest
  extends Omit<AccessoryItemBase, "uid"> {}

export interface AccessoryCommonLanguageChangeLogResponse {
  currentChangeCount?: number;
  lastResetDate?: string;
  changes: AccessoryCommonLanguageChangeLogResponseItem[];
}

export interface AccessoryCommonLanguageChangeLogResponseItem {
  id: string;
  revId: string;
  isInProgress: boolean;
  changes: CommonLanguageChangeLogResponseChange[];
}

export interface CommonLanguageChangeLogResponseChange {
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changeType: string;
  modifiedDate: string;
  modifiedBy: string;
}

export interface CommonLanguageVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}
