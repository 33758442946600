import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { trackPromise } from "react-promise-tracker";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Spinner,
  VehicleDashboard,
  VehicleTable,
  VehicleTableEmptyRow,
  VehicleTableRow,
} from "vapi-ui-common";
import apolloClient from "../../apolloClient";
import PageError from "../../components/PageError";
import {
  GST_REGION,
  NATIONAL_REGION,
  PUBLISHTYPE,
} from "../../constants/Constants";
import { Brand } from "../../gql/adminGenerated";
import {
  DownloadAccessoriesDocument,
  GetDashboardDocument,
  GetDashboardQuery,
  SeriesItem,
  SeriesMyItem,
  useCopyAccessoryDocumentMutation,
  useCreateAccessoryDraftMutation,
  useDeleteAccessoryDraftMutation,
  useGetDashboardQuery,
  usePublishDownstreamAccessoryDataMutation,
  useUploadAccessoriesMutation,
} from "../../gql/generated";
import useRouteParams from "../../hooks/useRouteParams";
import { Language } from "../../models/user/user.model";
import { VehicleTeam } from "../../models/vehicleData/vehicleData.model";
import UploadDraftModal, {
  UploadDraftModalRefProps,
} from "../../modules/VehicleDashboard/components/UploadDraftModal";
import useStores from "../../stores/useStores";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import cleanUpTypename from "../../utils/cleanUpTypename";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import { getParamsForVersionInfo } from "../../utils/vehicleDataUtils";
import { updateUserSeries } from "../../webservices/adminApi";
import styles from "./ChangeLog.module.scss";
import AdminOutOfSyncStatus from "./components/AdminOutOfSyncStatus";
import OutOfSyncStatusModel from "./components/OutOfSyncStatusModel";
import StatusModel from "./components/StatusModel";

const DashboardController = ({ history }: RouteComponentProps) => {
  const { userStore, dashboardStore, teamStore } = useStores();

  const [createDraft] = useCreateAccessoryDraftMutation();
  const [deleteDraft] = useDeleteAccessoryDraftMutation();
  const [copyDraft] = useCopyAccessoryDocumentMutation();
  const [publishAccessoryData] = usePublishDownstreamAccessoryDataMutation();
  const [uploadAccessories] = useUploadAccessoriesMutation();
  const uploadDraftModalRef = useRef<UploadDraftModalRefProps>();

  const [filteredData, setFilteredData] = useState<SeriesItem[]>([]);
  const [render, setRender] = useState(false);
  const [showSeriesManager, setShowSeriesManager] = useState(false);
  const { team } = useRouteParams();

  const isSpanish = team === VehicleTeam.SPANISH;
  const showCommonLanguage =
    teamStore.team.showCommonLanguage &&
    process.env.REACT_APP_COMMON_LANGUAGE === "true";

  const showSyncAmsAds =
    teamStore.team.allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true";

  const showOutOfSyncColumn = isSpanish || showCommonLanguage || showSyncAmsAds;

  const showSyncAdmin =
    teamStore.team.showSyncAdmin && process.env.REACT_APP_SYNC_ADMIN === "true";

  const writePerms = useMemo(() => {
    const teamLang = isSpanish ? Language.ES : Language.EN;
    return teamStore.team.langPermissions?.[teamLang] ?? {};
  }, [teamStore.team.langPermissions]);
  const hasWritePerms = !!writePerms.canEdit;

  const showCreateDraftButton = useCallback(
    (model: SeriesMyItem, models: SeriesMyItem[] = []) => {
      const hasDraft = !!models.find((m) => m.isDraft && m.year === model.year);
      if (model.isDraft || !writePerms.canEdit || hasDraft) return false;
      if (
        !isSpanish ||
        (process.env.REACT_APP_MULTI_LANG === "true" && !!model.spanishVersion)
      )
        return true;
      return false;
    },
    [writePerms]
  );

  // used for graphql payloads
  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region:
      team === VehicleTeam.NATIONAL_ACC_TEAM || isSpanish
        ? NATIONAL_REGION
        : GST_REGION,
    lang: isSpanish ? "es" : "en",
  };

  const { data, loading, error } = useGetDashboardQuery({
    variables: { ...userDetails },
    fetchPolicy: "network-only",
  });
  const teamTitle = `${capitalizeFirstLetter(team)} Accessories Team`;

  useEffect(() => {
    teamStore.setTeam(team, userStore.brand, userStore.langPermissions);
  }, [team, teamStore, userStore]);

  useEffect(() => {
    const dashboardSeriesArray =
      (data?.dashboard?.series as SeriesItem[]) ?? [];
    setFilteredData(dashboardSeriesArray);
  }, [data]);

  const handleSearchFilter = (query: string) => {
    const loweredSearchText = query.toLowerCase();
    const dashboardSeries = data?.dashboard?.series as SeriesItem[];
    if (dashboardSeries) {
      setFilteredData(
        dashboardSeries.filter(
          (item) =>
            item.seriesName.toLowerCase().indexOf(loweredSearchText) !== -1
        ) || []
      );
    }
  };

  const handleOnOpenSeriesManager = () => {
    setShowSeriesManager(true);
  };

  const handleOnRowClick =
    (model: SeriesMyItem, series: string) => (id: string, year: number) => {
      if (!isSpanish || process.env.REACT_APP_MULTI_LANG === "true") {
        const { versionInfoParams: version } = getParamsForVersionInfo(
          team,
          teamStore.team.languages,
          model
        );

        history.push(
          `/vehicleData/${team}/accessories/${id}/${year}/${series}/${version}`
        );
      }
    };

  const handleDeleteItem = async (
    seriesId: string,
    modelYear: number,
    isDraft = false
  ) => {
    try {
      await deleteDraft({
        variables: {
          ...userDetails,
          seriesId,
          modelYear,
        },
        update: (cache) => {
          const query = cache.readQuery<GetDashboardQuery>({
            query: GetDashboardDocument,
            variables: { ...userDetails },
          });

          const existingData = JSON.parse(
            JSON.stringify(query)
          ) as GetDashboardQuery;

          if (existingData.dashboard && existingData.dashboard.series) {
            existingData.dashboard.series.forEach((series) => {
              const seriesItem = series;
              if (seriesItem && seriesItem.seriesId === seriesId) {
                seriesItem.years = seriesItem.years?.filter((year) => {
                  return (
                    modelYear !== year.year ||
                    (modelYear === year.year && year.isDraft !== isDraft)
                  );
                });
              }
            });
          }

          cache.writeQuery({
            query: GetDashboardDocument,
            variables: { ...userDetails },
            data: existingData,
          });
        },
      });
      toast.success("Succesfully deleted draft");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleAddItem = async (
    seriesId: string,
    modelYear: number,
    version = 0
  ) => {
    try {
      await trackPromise(
        createDraft({
          variables: {
            ...userDetails,
            seriesId,
            modelYear,
            // version used in old logic - it was set to 1 by default
          },
          update: (cache, { data: newData }) => {
            const query = cache.readQuery<GetDashboardQuery>({
              query: GetDashboardDocument,
              variables: { ...userDetails },
            });

            const existingData = JSON.parse(
              JSON.stringify(query)
            ) as GetDashboardQuery;

            if (existingData.dashboard && existingData.dashboard.series) {
              existingData.dashboard.series.forEach((series) => {
                if (series?.seriesId === seriesId && series.years) {
                  series.years.push({
                    __typename: "SeriesMYItem",
                    isDraft: true,
                    year: modelYear,
                    version: version + 1,
                    createdDate: newData?.createAccDraft.draft.createdDate,
                  });
                }
              });
            }

            cache.writeQuery({
              query: GetDashboardDocument,
              variables: { ...userDetails },
              data: existingData,
            });
          },
        })
      );
      toast.success("Succesfully added draft");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleDuplicateItem = async (
    seriesId: string,
    modelYearFrom: number,
    modelYearTo: number
  ) => {
    try {
      await trackPromise(
        copyDraft({
          variables: {
            ...userDetails,
            seriesId,
            modelYearFrom,
            modelYearTo,
          },
          update: (cache, { data: newData }) => {
            const query = cache.readQuery<GetDashboardQuery>({
              query: GetDashboardDocument,
              variables: { ...userDetails },
            });

            const existingData = JSON.parse(
              JSON.stringify(query)
            ) as GetDashboardQuery;

            if (existingData.dashboard && existingData.dashboard.series) {
              existingData.dashboard.series.forEach((series) => {
                if (series?.seriesId === seriesId && series.years) {
                  series.years.push({
                    __typename: "SeriesMYItem",
                    isDraft: true,
                    year: modelYearTo,
                    version: 1,
                    createdDate: newData?.copyAccDoc.draft.createdDate,
                  });
                }
              });
            }

            cache.writeQuery({
              query: GetDashboardDocument,
              variables: { ...userDetails },
              data: existingData,
            });
          },
        })
      );
      toast.success("Succesfully duplicated model year");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleOnRemoveSeries = async (seriesId: string) => {
    try {
      const seriesArray = filteredData
        .filter((item) => item.seriesId !== seriesId)
        .map((item) => item.seriesId);

      const updatedUserSeries = await updateUserSeries({
        brand: userStore.brand as Brand,
        payload: {
          objectId: userStore.objectId,
          seriesSelections: seriesArray,
        },
      });

      const query = apolloClient.cache.readQuery<GetDashboardQuery>({
        query: GetDashboardDocument,
        variables: { ...userDetails },
      });

      // do something to query
      const existingData = JSON.parse(
        JSON.stringify(query)
      ) as GetDashboardQuery;
      if (existingData.dashboard) {
        existingData.dashboard.series = existingData.dashboard.series?.filter(
          (series) => series?.seriesId !== seriesId
        );
      }

      apolloClient.cache.writeQuery({
        query: GetDashboardDocument,
        variables: { ...userDetails },
        data: existingData,
      });

      // force rerender
      setRender(!render);

      dashboardStore.userSeries = updatedUserSeries.seriesSelections;
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleOnPublish = async (
    seriesId: string,
    modelYear: number,
    publishType: PUBLISHTYPE
  ) => {
    try {
      await trackPromise(
        publishAccessoryData({
          variables: {
            ...userDetails,
            seriesId,
            modelYear,
            publishType,
          },
          update: (_cache, { data: newData }) => {
            const query = _cache.readQuery<GetDashboardQuery>({
              query: GetDashboardDocument,
              variables: { ...userDetails },
            });

            const existingData = JSON.parse(
              JSON.stringify(query)
            ) as GetDashboardQuery;

            if (existingData.dashboard && existingData.dashboard.series) {
              existingData.dashboard.series.forEach((series) => {
                const seriesItem = series;
                if (seriesItem && seriesItem.seriesId === seriesId) {
                  seriesItem.years = seriesItem.years?.map((year) => {
                    if (year.isDraft || year.year !== modelYear) {
                      return year;
                    }
                    return {
                      ...year,
                      publishDownstreamDate:
                        newData?.publishDownstreamAccData.seriesItem
                          .publishDownstreamDate,
                      publishDownstreamVersion:
                        newData?.publishDownstreamAccData.seriesItem
                          .publishDownstreamVersion,
                      previewDownstreamDate:
                        newData?.publishDownstreamAccData.seriesItem
                          .previewDownstreamDate,
                      previewDownstreamVersion:
                        newData?.publishDownstreamAccData.seriesItem
                          .previewDownstreamVersion,
                    };
                  });
                }
              });
            }

            _cache.writeQuery({
              query: GetDashboardDocument,
              variables: { ...userDetails },
              data: existingData,
            });
          },
        })
      );

      if (publishType === PUBLISHTYPE.PUBLISH) {
        toast.success(`Successfully published`);
        toast.success(`Successfully published ${PUBLISHTYPE.PREVIEW}`);
      } else {
        toast.success(`Successfully published ${PUBLISHTYPE.PREVIEW}`);
      }
    } catch (e) {
      handleErrorResponse(e, "Error Publishing");
    }
  };

  const handleOnUpload = useCallback(
    async (file?: File, resultJSON?: object) => {
      if (!resultJSON) return;
      try {
        let obj: any = {};
        obj = resultJSON;
        obj.downloadAccDocument.header.sourceVersion =
          obj.downloadAccDocument.header.publishedVersion;
        const header = cleanUpTypename(obj.downloadAccDocument.header);
        const refItems = cleanUpTypename(obj.downloadAccDocument.refItems);
        const accessories = cleanUpTypename(
          obj.downloadAccDocument.accessories
        );
        const lang = "es";

        await trackPromise(
          uploadAccessories({
            variables: {
              lang,
              header,
              refItems,
              accessories,
            },
            update: (_cache, { data: newData }) => {
              if (!newData) return;

              const query = _cache.readQuery<GetDashboardQuery>({
                query: GetDashboardDocument,
                variables: { ...userDetails },
              });

              const existingData = JSON.parse(
                JSON.stringify(query)
              ) as GetDashboardQuery;

              if (existingData.dashboard && existingData.dashboard.series) {
                existingData.dashboard.series.forEach((series) => {
                  const seriesItem = series;
                  const { modelYear, publishedVersion, seriesId, createdDate } =
                    newData.uploadAccDocument.document;

                  if (seriesItem.seriesId === seriesId) {
                    if (!seriesItem.years) return;

                    const filterYear = seriesItem.years.filter(
                      (year) => year.year === modelYear
                    );

                    if (filterYear) {
                      const version = publishedVersion ?? 0;
                      filterYear.forEach((year) => {
                        const updateVersion = year;
                        updateVersion.spanishVersion = version;
                      });

                      seriesItem.years.push({
                        __typename: "SeriesMYItem",
                        isDraft: true,
                        year: modelYear,
                        spanishVersion: version + 1,
                        createdDate,
                      });
                    }
                  }
                });
              }

              _cache.writeQuery({
                query: GetDashboardDocument,
                variables: { ...userDetails },
                data: existingData,
              });
            },
          })
        );
        toast.success("Successfully uploaded file");
      } catch (e) {
        handleErrorResponse(e, "Error uploading document");
      }
    },
    [uploadAccessories]
  );

  const handleOnDownload = async (
    seriesId: string,
    seriesName: string,
    engVersion: number,
    year: number
  ) => {
    try {
      const version = engVersion;
      const modelYear = year;
      const region = NATIONAL_REGION;
      const lang = "en";
      const fileName = `${seriesName}-${modelYear}-version-${version}`;
      const documentData = await apolloClient.query({
        query: DownloadAccessoriesDocument,
        variables: {
          ...userDetails,
          lang,
          region,
          seriesId,
          modelYear,
          version,
        },
      });
      const json = JSON.stringify(documentData.data, undefined, 4);
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = `${fileName}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      handleErrorResponse(e, "Error downloading document");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <PageError />;
  }

  if (showSeriesManager) {
    return <Redirect to={`/${team}/seriesManager`} />;
  }

  const onFileUpload = (model: SeriesMyItem, serie: SeriesItem) => {
    if (model.isDraft) return undefined;

    const models = (serie.years as SeriesMyItem[]) ?? [];
    const hasDraft = !!models.find((m) => m.isDraft && m.year === model.year);

    if (hasDraft) return uploadDraftModalRef.current?.open;
    return handleOnUpload;
  };

  return (
    <>
      <VehicleDashboard
        teamTitle={teamTitle}
        onSearch={handleSearchFilter}
        onAddVehiclesClick={handleOnOpenSeriesManager}
      >
        {filteredData.map((item: SeriesItem) => (
          <VehicleTable
            key={item.seriesId}
            seriesId={item.seriesId}
            series={item.seriesName}
            modelYears={item.years?.map((year) => year.year.toString())}
            onAddItem={handleAddItem}
            onDuplicateItem={handleDuplicateItem}
            onRemoveSeries={handleOnRemoveSeries}
            canAddYear={!isSpanish && hasWritePerms}
            isSpanish={isSpanish}
            statusColumn={isSpanish}
            outOfSyncColumn={showOutOfSyncColumn}
            adminOutOfSyncColumn={showSyncAdmin}
          >
            {!item?.years?.length && (
              <VehicleTableEmptyRow
                statusColumn={isSpanish}
                outOfSyncColumn={showOutOfSyncColumn}
                adminOutOfSyncColumn={showSyncAdmin}
                className={cx(styles.adminOutOfSync, styles.outOfSync)}
              />
            )}
            {item?.years?.map((model: SeriesMyItem) => (
              <VehicleTableRow
                key={`year:${model?.year}-version:${model?.version}${
                  model?.isDraft ? "-isDraft" : ""
                }`}
                year={model?.year || 0}
                date={
                  isSpanish
                    ? model.publishDownstreamDate || ""
                    : model?.publishedDate || ""
                }
                version={model?.version?.toString() || "1"}
                notes=""
                seriesId={item.seriesId}
                seriesName={item.seriesName}
                onRowClick={handleOnRowClick(model, item.seriesName)}
                useNewStatus
                onDeleteItem={(seriesId: string, modelYear: number) => {
                  handleDeleteItem(seriesId, modelYear, model.isDraft);
                }}
                onCreateDraft={(seriesId: string, modelYear: number) => {
                  handleAddItem(seriesId, modelYear, model.version || 0);
                }}
                showCreateDraftButton={showCreateDraftButton(
                  model,
                  item.years as SeriesMyItem[]
                )}
                showRemoveDraftButton={
                  model.isDraft &&
                  hasWritePerms &&
                  (!isSpanish || process.env.REACT_APP_MULTI_LANG === "true")
                }
                onPublish={(seriesId: string, modelYear: number) => {
                  handleOnPublish(seriesId, modelYear, PUBLISHTYPE.PUBLISH);
                }}
                onPreview={(seriesId: string, modelYear: number) => {
                  handleOnPublish(seriesId, modelYear, PUBLISHTYPE.PREVIEW);
                }}
                showPublishButton={
                  !model.isDraft &&
                  (hasWritePerms ||
                    (isSpanish &&
                      model.spanishVersion !== model.publishDownstreamVersion))
                }
                showPreviewButton={
                  !model.isDraft &&
                  (hasWritePerms ||
                    (isSpanish &&
                      model.spanishVersion !== model.previewDownstreamVersion))
                }
                alwaysShowIcons
                disablePublishButton={
                  typeof model.publishDownstreamVersion === "number" &&
                  (model.version === model.publishDownstreamVersion ||
                    (isSpanish &&
                      model.spanishVersion === model.publishDownstreamVersion))
                }
                disablePreviewButton={
                  typeof model.previewDownstreamVersion === "number" &&
                  (model.version === model.previewDownstreamVersion ||
                    (isSpanish &&
                      model.spanishVersion === model.previewDownstreamVersion))
                }
                isSpanish={isSpanish}
                onDownload={!model.isDraft ? handleOnDownload : undefined}
                onFileUpload={onFileUpload(model, item)}
                englishVersion={model.englishVersion || 0}
                spanishVersion={model.spanishVersion || 0}
                statusColumn={isSpanish}
                status={<StatusModel model={model} />}
                outOfSyncColumn={showOutOfSyncColumn}
                outOfSyncStatus={
                  <OutOfSyncStatusModel model={model} seriesItem={item} />
                }
                adminOutOfSyncColumn={showSyncAdmin}
                adminOutOfSyncStatus={
                  <AdminOutOfSyncStatus model={model} seriesItem={item} />
                }
                className={cx(styles.adminOutOfSync, styles.outOfSync)}
              />
            ))}
          </VehicleTable>
        ))}
      </VehicleDashboard>

      <UploadDraftModal
        ref={uploadDraftModalRef}
        handleOnUpload={handleOnUpload}
        handleDeleteItem={handleDeleteItem}
      />
    </>
  );
};

export default withRouter(observer(DashboardController));
