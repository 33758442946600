import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import React from "react";
import { Button } from "vapi-ui-common";
import styles from "./amsButton.module.scss";

export interface AmsButtonProps {
  onClick?: () => void;
}

const AmsButton = ({ onClick = () => undefined }: AmsButtonProps) => {
  return (
    <Button
      className={cx(styles.syncAmsAdsBtn, {
        [styles.hasUpdatesBtn]: true,
      })}
      onClick={onClick}
      data-testid="ams-button-comp"
    >
      <FontAwesomeIcon
        className={cx(styles.warningIcon, { [styles.hideIcon]: false })}
        icon={faExclamationTriangle}
      />
      <span>AMS</span>
    </Button>
  );
};

export default AmsButton;
