import React from "react";
import {
  Button,
  HeaderCell,
  HeaderRow,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Thead,
} from "vapi-ui-common";
import ChangeLogRow from "../ChangeLogTable/ChangeLogRow";
import ChangeLogChanges from "../ChangeLogTable/ChangeLogChanges";
import styles from "./amsModal.module.scss";
import {
  ChangeLogBase,
  ChangeLogTypes,
} from "../../models/accessories/changeLog.model";

export interface AmsModalProps {
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
}

const AmsModal = ({ openModal, setOpenModal }: AmsModalProps) => {
  const onClose = () => {
    setOpenModal(false);
  };

  const onClick = () => {
    onClose();
  };

  const item: ChangeLogBase = {
    id: "123",
    revId: "v123",
    before: "Past value",
    after: "Current value",
    beforeValue: "Past value",
    afterValue: "Current value",
    modifiedDate: "",
    modifiedBy: "",
    changeType: ChangeLogTypes.DESCRIPTION,
  };

  return (
    <div data-testid="ams-modal-comp">
      <Modal
        className={styles.modalContainer}
        open={openModal}
        onClose={onClose}
      >
        <ModalHeader onClose={onClose}>Changes from AMS</ModalHeader>
        <ModalBody className={styles.modalBody}>
          <Table fullWidth>
            <Thead>
              <HeaderRow>
                <HeaderCell>Title</HeaderCell>
                <HeaderCell>Change Type</HeaderCell>
                <HeaderCell>Changes</HeaderCell>
              </HeaderRow>
            </Thead>
            <tbody>
              <>
                <ChangeLogRow
                  key="Ams0"
                  description="name"
                  changeType="change type"
                  modifiedBy=""
                  modifiedDate=""
                  notes=""
                  canRevert={false}
                  hideModifiedBy
                  hideModifyDate
                  hideNotes
                  hideRevert
                  onRevert={() => {}}
                  renderChanges={<ChangeLogChanges changeItem={item} />}
                />
                <ChangeLogRow
                  key="Ams1"
                  description="name"
                  changeType="change type"
                  modifiedBy=""
                  modifiedDate=""
                  notes=""
                  canRevert={false}
                  hideModifiedBy
                  hideModifyDate
                  hideNotes
                  hideRevert
                  onRevert={() => {}}
                  renderChanges={<ChangeLogChanges changeItem={item} />}
                />
                <ChangeLogRow
                  key="Ams2"
                  description="name"
                  changeType="change type"
                  modifiedBy=""
                  modifiedDate=""
                  notes=""
                  canRevert={false}
                  hideModifiedBy
                  hideModifyDate
                  hideNotes
                  hideRevert
                  onRevert={() => {}}
                  renderChanges={<ChangeLogChanges changeItem={item} />}
                />
              </>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            variant="transparent"
            onClick={onClose}
            className={styles.unlinkButton}
          >
            Unlink from AMS
          </Button>
          <Button variant="transparent" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClick}>
            Accept All
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AmsModal;
